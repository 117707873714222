export class ModeloPase {
	constructor(
		public id:number,
		public tipo: any,
		public referente: string,
		public destinatario: string,
		public area_id: number,
		public user_id: number,
		public titulo: string,
		public contenido: string,
		public created_at: any,
		public updated_at: any
	){}
}
