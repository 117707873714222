import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { SecgralService } from '../../services/secgral.service';

@Component({
	selector: 'app-editar-norma',
	templateUrl: './editar-norma.component.html',
	styleUrls: ['./editar-norma.component.css']
})
export class EditarNormaComponent implements OnInit {

	id: string;
	normaLegal:any;
	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;
	deleted: boolean;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private location: Location,
				private route: ActivatedRoute,
				private router: Router,
				private secgralService: SecgralService) {
		this.sending = false;
		this.okStatus = false;
		this.errorStatus = false;

		this.normaLegal = null;

		this.route.paramMap.subscribe(params => {
		this.id = params.get("id")

		this.secgralService.getNorma(this.id)
		.subscribe( (data: any) => {
			this.normaLegal = data.normaLegal;
		}, error => {
			console.log(error);
		});
	});
	}

	ngOnInit() {
	}

	editar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;
		this.deleted = false;

		delete this.normaLegal.user;
		delete this.normaLegal.modelo;
		this.normaLegal.tipo = this.normaLegal.tipo.id == 1 ? 1 : 2;
		this.normaLegal.reservado = false;

		this.secgralService.editNorma(this.id, this.normaLegal)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			setTimeout(() => {	
				this.router.navigate(['/normas-legales/' + this.id]);
			}, 3000);
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
	}

	eliminarNorma(id){
		(<any>$('#deleteModal')).modal('hide');
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;
		this.deleted = false;

		this.secgralService.delNorma(id)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;
			this.deleted = true;

			setTimeout(() => {	
				this.router.navigate(['/normas-legales']);
			}, 3000);
		}, error => {
			this.sending = false;
			this.errorStatus = true;
			console.log(error);
		});
	}

	atras(){
		this.router.navigate(['/normas-legales/' + this.id]);
	}

}
