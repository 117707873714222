import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	user: User;
	userEmail: string = null;
	userPassword: string = null;
	rememberUser: boolean = true;
	token: string = null;

	sending: boolean = false;
	okStatus: boolean = false;
	errorStatus: boolean = false;
	
	constructor(private auth: AuthService,
				private router: Router) { }

	ngOnInit() {
		if(localStorage.getItem('user') && localStorage.getItem('user') != 'undefined'){
			this.userEmail = localStorage.getItem('user');
		}
	}

	login(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.user = new User(null, null, this.userEmail, this.userPassword, null);

		this.auth.login(this.user)
		.subscribe((data:any) => {
			//console.log(data);
			this.sending = false;
			this.okStatus = true;

			this.token = data;

			this.getUserData();

		}, error => {
			console.log(error);

			this.sending = false;
			this.errorStatus = true;
		})
	}

	private getUserData(){
		this.auth.getUserData()
		.subscribe( (data:any) => {
			let loginCard = document.getElementById("card-signin");
			loginCard.classList.add("moveUp");
			
			let backgroundLogin = document.getElementById("wrapper");
			backgroundLogin.classList.add("fadeOnLogin");
			
			//console.log(data);
			setTimeout( () => {
				this.router.navigateByUrl('/home');
			}, 1200);
		}, error => {
			console.log(error);
		});
	}

}
