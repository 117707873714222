import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { NumeroALetrasService } from '../../services/numero-aletras.service';
import { NormaLegal } from '../../models/norma-legal.model';
import { NgForm } from '@angular/forms';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-new-norma',
  templateUrl: './new-norma.component.html',
  styleUrls: ['./new-norma.component.css']
})
export class NewNormaComponent implements OnInit {

	normaLegal: NormaLegal;
	numExpediente: string;
	descripcion: string = null;
	modelo: any;
	campos: any;
	id: any;
	tipo: any;
	copia: any;
	normaLegalForm:any;

	visto: string;
	considerando: string;
	articulado: string;

	vistoTemp: string;
	considerandoTemp: string;
	articuladoTemp: string;

	considerandoArray: any;
	articuladoArray: any;

	camposArray:any;

	loadingModelo: boolean = true;
	loadingCopia: boolean = true;

	autoUpper: boolean = false;
	stage: number;
	editing: boolean;
	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;

	nivelPermisos: any = localStorage.getItem('access');

  constructor(private route: ActivatedRoute,
			private router: Router,
			private secgralService: SecgralService,
			private conversor: NumeroALetrasService) {
				
  	this.sending = false;
	this.okStatus = false;
	this.errorStatus = false;

	this.numExpediente = "";
	this.modelo = null;
	this.campos = null;
	this.id = null;
	this.tipo = null;
	this.copia = null;
	this.stage = 1;
	this.editing = false;

	this.camposArray = new Object;

	this.visto = null
	this.considerando = null
	this.articulado = null

	this.vistoTemp = null;
	this.considerandoTemp = null;
	this.articuladoTemp = null;

	this.considerandoArray = null;
	this.articuladoArray = null;

	this.route.paramMap.subscribe(params => {
		this.tipo = params.get("tipo");

		if (this.tipo == "resolucion" || this.tipo == "resolución") {
			this.tipo = 1;
		}
		else if (this.tipo == "decreto") {
			this.tipo = 2;
		}

		this.id = params.get("modeloId") == "null" ? null : params.get("modeloId");
		this.numExpediente = params.get("expediente") == "null" ? "" : params.get("expediente");
		this.copia = params.get("copiaId") == "null" ? null : params.get("copiaId");

	  if (this.id) {
		secgralService.getModelo(this.id)
		  .subscribe( (data:any) => {
			this.modelo = data.modelo;
			this.visto = this.modelo.visto;
			this.considerando = this.modelo.considerando;
			this.articulado = this.modelo.articulado;

			
			this.loadingModelo = false;
			this.loadingCopia = false;
			
			this.campos = JSON.parse(this.modelo.campos);
			
			this.vistoTemp = this.visto;
			this.considerandoTemp = this.considerando;
			this.articuladoTemp = this.articulado;
			this.reemplazar();
		});
	  }
	  else if(!this.id && (this.tipo == 1)){
		this.visto = 'El Expediente N° ##expediente## del registro de este Ministerio; y';
		this.loadingModelo = false;

		this.vistoTemp = this.visto;
		this.reemplazarNumExpediente();
	  }
	  else if(!this.id && this.tipo == 2){
		this.visto = 'El Expediente N° ##expediente## del registro del Ministerio de Salud Pública; y';
		this.loadingModelo = false;

		this.vistoTemp = this.visto;
		this.reemplazarNumExpediente();
	  }

	  
	  else if(this.copia){
		this.loadingModelo = false;
	  	secgralService.getNorma(this.copia)
		.subscribe( (data:any) => {
			this.modelo = data.normaLegal.modelo;
			this.id = this.modelo ? this.modelo.id : null;
			this.tipo = data.normaLegal.tipo.id;
			if (data.normaLegal.visto.indexOf("El Expediente") == 0){
				this.visto = this.tipo == 1 ? 'El Expediente N° ##expediente## del registro de este Ministerio; y' : 'El Expediente N° ##expediente## del registro del Ministerio de Salud Pública; y';
			}else{
				this.visto = data.normaLegal.visto;
			}
			this.considerando = data.normaLegal.considerando;
			this.articulado = data.normaLegal.articulado;
			this.loadingCopia = false;
			
			this.vistoTemp = this.visto;
			this.numExpediente = "";
			this.reemplazarNumExpediente();
			
			this.separarParrafos();
		});
	  }
	  if (!this.id && !this.copia){
			this.loadingModelo = false;
			this.loadingCopia = false;
		}
	});
  }

	ngOnInit() {	
	}

	reemplazar(){
		this.visto = this.vistoTemp;
		if(!this.copia && this.id){
			this.considerando = this.considerandoTemp;
			this.articulado = this.articuladoTemp;
		}

		this.reemplazarNumExpediente();
		if(this.campos){
			this.reemplazarValoresCampos();
		}
	}

	reemplazarNumExpediente(){
		let value = this.numExpediente;
		value = this.numExpediente != "" ? value : "(EXPEDIENTE)";

		this.visto = this.visto ? this.visto.split("##expediente##").join(value) : null;
		this.considerando = this.considerando ? this.considerando.split("##expediente##").join(value) : null;
		this.articulado = this.articulado ? this.articulado.split("##expediente##").join(value) : null;
	}

	reemplazarValoresCampos(){
		for (let campo of this.campos.campos) {
			if (campo.selector.indexOf("en letras: ") == 0){
				let posicion = campo.selector.indexOf(":") + 1;
				let campoNumero = campo.selector.substring(posicion).trim();
				let valor = null;
				for (let c of this.campos.campos) {
					if (c.selector == campoNumero){
						valor = c.default;
					}
				}
				valor = valor.split(".").join("").split(",").join(".");
				if (this.conversor.numeroALetras(Number(valor)).indexOf("undefined") == -1){
					campo.default = this.conversor.numeroALetras(Number(valor));
				}else{
					campo.default = "";
				}
			}
			
			let value = this.autoUpper ? campo.default.toUpperCase() : campo.default;
			value = campo.default != "" ? value : "(" + campo.titulo.toUpperCase() + ")";


			this.visto = this.visto ? this.visto.split("##" + campo.selector + "##").join(value) : null;
			this.considerando = this.considerando ? this.considerando.split("##" + campo.selector + "##").join(value) : null;
			this.articulado = this.articulado ? this.articulado.split("##" + campo.selector + "##").join(value) : null;
		}
		this.separarParrafos();
	}
	
	separarParrafos(){
		this.considerandoArray = this.considerando ? this.considerando.split("\n") : null;
		this.articuladoArray = this.articulado ? this.articulado.split("\n") : null;
	}

	vistaPrevia(){
		this.separarParrafos();
		this.stage = 2;
	}

	editar(){
		this.stage = 1;
		this.editing = true;
	}

	guardar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.normaLegal = new NormaLegal(null, this.tipo, null, null, this.numExpediente, localStorage.getItem('sub'), this.id, false, this.descripcion, this.visto, this.considerando, this.articulado, null, null);
		this.secgralService.newNorma(this.normaLegal)
		.subscribe( (data:any) =>{
			this.sending = false;
			this.okStatus = true;
			//console.log(data);
			let id = data.normaLegal.id;

			setTimeout(() => {
				this.router.navigate(['/normas-legales', id]);		
			}, 3000);
			
		}, error => {
			this.sending = false;
			this.errorStatus = true;
			console.log(error);
		})
	}
}
