import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { SecgralService } from '../../services/secgral.service';
import { ImprimirService } from '../../services/imprimir.service';
import { NormaLegal } from '../../models/norma-legal.model';


@Component({
	selector: 'app-detalle-normas',
	templateUrl: './detalle-normas.component.html',
	styleUrls: ['./detalle-normas.component.css']
})
export class DetalleNormasComponent implements OnInit {

	id: string;
	normaLegal:NormaLegal;
	fecha: Date = new Date();
	numeroPropuesto: number = null;

	visto: string = null;
	considerando: string = null;
	articulado: string = null;
	
	considerandoArray: any = null;
	articuladoArray: any = null;

	pdfContent:any[];

	numeroDisponible: boolean = true;
	validandoNumRes: boolean = false;;
	numerando: boolean = false;;
	numerarStatus: boolean = false;;
	errorStatus: boolean = false;;

	pageSize: string;
	fontSize: number;
	margenSuperior: number;
	margenInferior: number;
	margenDerecho: number;
	margenIzquierdo: number;
	indent: number;
	header: boolean;
	negrita: number;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private location: Location,
				private router: Router,
				private route: ActivatedRoute,
				private secgralService: SecgralService,
				private imprimir: ImprimirService) {

		this.route.paramMap.subscribe(params => {
			this.id = params.get("id")

			this.secgralService.getNorma(this.id)
			.subscribe( (data: any) => {
				this.normaLegal = data.normaLegal;				
				this.visto = this.normaLegal.visto;
				this.considerando = this.normaLegal.considerando;
				this.articulado = this.normaLegal.articulado;

				if (!this.normaLegal.numero && this.normaLegal.tipo.id == 1) {
					//this.checkNumeroDisponible();
					this.normaLegal.fecha = this.fecha;
					this.numeroDisponible = true;
					this.actualizarNumDisponible(this.normaLegal.fecha.getFullYear());
				}

				if(this.considerando){
					this.considerandoArray = this.considerando.split("\n");
				}

				if (this.articulado) {
					this.articuladoArray = this.articulado.split("\n");
				}

				this.defaultPrintValues();

			}, error => {
				console.log(error);
			});
		});
	}

	ngOnInit() {
	}

	generarPDF(download = false){
		let params = {
			pageSize: this.pageSize,
			fontSize: this.fontSize,
			margenSuperior: this.margenSuperior,
			margenInferior: this.margenInferior,
			margenDerecho: this.margenDerecho,
			margenIzquierdo: this.margenIzquierdo,
			indent: this.indent,
			header: this.header,
			negrita: this.negrita,
		};
		this.imprimir.generarPDF(this.normaLegal, params, download);
	}

	checkNumDisponible(e){
	if (e.target.value) {
	  let newYear = (new Date(e.target.value)).getFullYear();

	  if (newYear != this.fecha.getFullYear()) {
		this.actualizarNumDisponible(newYear);
	  }

	  this.fecha = new Date(e.target.value);
	}
  }

  checkNumeroDisponible(){
  	this.validandoNumRes = true;
  	//console.log(this.normaLegal.numero);

	  let tipo = this.normaLegal.tipo.id == 1 ? 'resoluciones' : 'decretos';
	  //console.log(tipo);
	  this.secgralService.checkNumDisponible(tipo, this.numeroPropuesto, this.fecha.getFullYear())
	  .subscribe( (data:any) => {
		//console.log(data);
		if (data.result.length == 0) {
		  //console.log("NUMERO DISPONIBLE");
		  this.numeroDisponible = true;
		}else{
		  //console.log("NUMERO NO DISPONIBLE");
		  this.numeroDisponible = false;
		}
		this.validandoNumRes = false;
	  }, error => {
		console.log(error);
	  });  
	
  }

  actualizarNumDisponible(year){
	  this.secgralService.getNumeroResDisponible(year)
	  .subscribe( ( data:any ) => {
		if(data.ultima){
		  this.numeroPropuesto = data.ultima.numero + 1;
		  //console.log(this.numRes);
		}
		else{
		  this.numeroPropuesto = 1;
		}
	  }, error => {
		console.log(error);
	  });
  }

  numerarNorma(id){
  	this.numerando = true;
	this.errorStatus = false;

  	let normaLegalNumerar = new NormaLegal(
  		this.normaLegal.id,
		this.normaLegal.tipo.id,
		this.numeroPropuesto,
		this.normaLegal.fecha,
		this.normaLegal.num_expte,
		this.normaLegal.user_id,
		this.normaLegal.modelo_id,
		this.normaLegal.reservado,
		this.normaLegal.descripcion,
		this.normaLegal.visto,
		this.normaLegal.considerando,
		this.normaLegal.articulado,
		this.normaLegal.created_at,
		this.normaLegal.updated_at);
	console.log(normaLegalNumerar);
	
	this.secgralService.editNorma(id, normaLegalNumerar)
	.subscribe( (data: any) => {
		console.log(data);
		this.numerando = false;
		this.numerarStatus = true;
		setTimeout(() => {
		  (<any>$('#numerarModal')).modal('hide');
		  this.numerarStatus = false;
		  this.onRefresh();
		}, 2000);
	}, error => {
		this.numerando = false;
		this.errorStatus = true;
		this.checkNumeroDisponible();
		console.log(error);
	});
  }

  borrarNumero(id){
	console.log(id);
	let normaLegalNumerar = new NormaLegal(
		this.normaLegal.id,
		this.normaLegal.tipo.id,
		null,
		null,
		this.normaLegal.num_expte,
		this.normaLegal.user_id,
		this.normaLegal.modelo_id,
		this.normaLegal.reservado,
		this.normaLegal.descripcion,
		this.normaLegal.visto,
		this.normaLegal.considerando,
		this.normaLegal.articulado,
		this.normaLegal.created_at,
		this.normaLegal.updated_at);

		this.secgralService.editNorma(id, normaLegalNumerar)
		.subscribe( (data: any) => {
			console.log(data);
			setTimeout(() => {
			  this.onRefresh();
			}, 100);
		}, error => {
			console.log(error);
		});
	}

	defaultPrintValues(){
		this.pageSize = "LEGAL";
		this.margenDerecho = 2;
		this.fontSize = 12;
		this.header = true;

		if (this.normaLegal.tipo.id == 1) {
			this.margenSuperior = 6;
			this.margenInferior = 3;
			this.margenIzquierdo = 4;
			this.negrita = 0;
			this.indent = 60;
		}
		else if (this.normaLegal.tipo.id == 2) {
			this.margenSuperior = 8;
			this.margenInferior = 6;
			this.margenIzquierdo = 5;
			this.negrita = 3;
			this.indent = 90;
		}
	}

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
		.then(() => {
			this.router.navigated = false;
			this.router.navigate([this.router.url]);
		});
	}
}

