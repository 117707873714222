import { Component, OnInit } from '@angular/core';
import { SecgralService } from '../../services/secgral.service';

@Component({
  selector: 'app-tabla-pases',
  templateUrl: './tabla-pases.component.html',
  styleUrls: ['./tabla-pases.component.css']
})
export class TablaPasesComponent implements OnInit {

	pases:any;
	searchTerm: string;
	tiposFilter: string;
	loading: boolean;

	constructor(private secgralService: SecgralService) {

		this.tiposFilter = "todo";
		this.loading = true;
		this.pases = [];

		this.secgralService.getPases()
		.subscribe( (data:any) => {
			this.pases = data.pases;
			this.loading = false;
		}, error => {
			console.log(error);
			this.loading = false;
		});

	}

	ngOnInit() {
	}

}
