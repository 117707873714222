import { Component, OnInit } from '@angular/core';
import { SecgralService } from '../../services/secgral.service';
import * as PDFDocument from 'pdfkit';
import * as blobStream from 'blob-stream';

@Component({
  selector: 'app-recibo-expedientes',
  templateUrl: './recibo-expedientes.component.html',
  styleUrls: ['./recibo-expedientes.component.css']
})
export class ReciboExpedientesComponent implements OnInit {

  expedientes: any = [];
  expedientesEnRecibo: any = [];
  loading: boolean = false;
  searchTerm: string = null;

  fecha: Date;
  fechaString: any;

  pageSize: string;
  margenSuperior: number;
  margenInferior: number;
  margenDerecho: number;
  margenIzquierdo: number;
  imprimirPorHoja: number;

  constructor(private secgralService: SecgralService) {
    this.getExpedientes();
    this.defaultPrintValues();
  }
  
  ngOnInit() {
  }
  
  getExpedientes(){
    this.loading = true;
    this.secgralService.getExpedientes()
    .subscribe((data: any) => {
      this.expedientes = data.expedientes;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  agregarExpedienteAListado(expediente){
    if(!this.estaEnListado(expediente)){
      //this.expedientesEnRecibo.push({ info: expediente, mensaje: '' });
      this.expedientesEnRecibo.push({
        ...expediente,
        mensaje: ''
      });
    }
  }

  estaEnListado(expediente){
    for(let exp of this.expedientesEnRecibo){
      if (expediente.id == exp.id){
        return true;
      }
    }
    return false;
  }

  eliminarDeRecibo(i){
    this.expedientesEnRecibo.splice(i, 1);
  }

  generarPDF(download = false) {
    const doc = this.generarDoc();
    const stream = doc.pipe(new blobStream());

    this.pdfContenido(doc);

    /*	FINALIZAR	*/
    this.endDoc(doc, stream, download);
  }

  generarDoc() {
    return new PDFDocument({
      size: this.pageSize,
      margins: {
        top: (this.margenSuperior / 2.54 * 72),
        bottom: (this.margenInferior / 2.54 * 72),
        left: (this.margenIzquierdo / 2.54 * 72),
        right: (this.margenDerecho / 2.54 * 72)
      },
      bufferPages: true
    });
  }

  pdfContenido(doc) {
    for (let i = 0; i < this.imprimirPorHoja; i++){
      if(i != 0){
        doc.moveDown();
        doc.moveDown();
        doc.moveDown();
      }
      doc.fontSize(13);
      doc.font('Times-Bold');
      doc.text("MINISTERIO DE SALUD PÚBLICA", {align: 'center'});
      doc.text("SECRETARÍA GENERAL", {align: 'center'});
      doc.moveDown();
      doc.text(this.fecha.toLocaleDateString('es-AR', { year: 'numeric', month: 'long', day: '2-digit', timeZone: 'UTC'}), {align: 'right'})
      doc.text("EXPEDIENTES ENVIADOS:");
      doc.moveDown();
      
      doc.fontSize(12);
  
      for(let expediente of this.expedientesEnRecibo){
        doc.font('Times-Bold');
  
        doc.list(["Expediente: " + expediente.num_expte]);
  
        doc.font('Times-Roman');
  
        doc.text(expediente.extracto);
        doc.text(expediente.mensaje);
  
        doc.font('Times-Italic');
        doc.text("Area destino: " + expediente.destino.area);
        doc.moveDown();
      }
    }

  }

  endDoc(doc, stream, download = false) {
    let filename = "Recibo.pdf";

    doc.end();
    stream.on('finish', function () {
      const url = stream.toBlobURL('application/pdf');

      if (download) {
        // PARA DESCARGAR PDF DIRECTAMENTE
        let link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        window.open(url);
      }
    });
  }

  defaultPrintValues() {
    this.pageSize = "LEGAL";
    this.margenSuperior = 3;
    this.margenInferior = 2;
    this.margenDerecho = 2;
    this.margenIzquierdo = 2;
    this.imprimirPorHoja = 1;

    this.fecha = new Date();
    this.fechaString = this.fecha.toISOString().split('T')[0];
  }

  actualizarFecha(){
    this.fecha = new Date(this.fechaString);
  }

}
