import { Pipe, PipeTransform } from '@angular/core';
import { NormaLegal } from '../models/norma-legal.model';

@Pipe({
	name: 'tipoNormalLegal'
})
export class TipoNormalLegalPipe implements PipeTransform {

	transform(NormasLegales: NormaLegal[], tipoNorma: string): NormaLegal[] {
		
		let tipoNormaId;

		if(tipoNorma == 'resolucion'){
			tipoNormaId = 1;
		}
		else if(tipoNorma == 'decreto'){
			tipoNormaId = 2;
		}

		if (!NormasLegales || !tipoNorma || tipoNorma == 'todo') {
			return NormasLegales;
		}
		return NormasLegales.filter( normaLegal =>
			normaLegal.tipo.id == tipoNormaId); 	
	}

}
