import {
   transition,
   trigger,
   query,
   style,
   animate,
   group,
   animateChild
} 
from '@angular/animations';
export const slideInAnimation =
    trigger('routeAnimations', [
        transition('normas <=> normas', []),
        transition('expedientes <=> expedientes', []),
        transition('* <=> *', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    opacity: 1
                })
            ]),
            query(':enter', [
                style({ left: '-100%' })
            ]),
            query(':leave', animateChild(), { optional: true }),
            group([
                query(':leave', [
                    animate('300ms ease', style({ transform: 'scale(0.5)', opacity: 0, left: '100%' }))
                ], { optional: true }),
                query(':enter', [
                    animate('300ms ease', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ])
    ]);