import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { Modelo } from '../../models/modelo.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'app-new-modelo',
	templateUrl: './new-modelo.component.html',
	styleUrls: ['./new-modelo.component.css']
})
export class NewModeloComponent implements OnInit {

	modelo: Modelo;
	categorias: any;
	tipo: string;
	titulo: string;
	categoria: string;
	descripcion: string;
	campos: any;
	visto: string;
	considerando: string;
	articulado: string;

	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;
	
	stage: number = 1;

	constructor(private router: Router,
				private secgralService: SecgralService) {

		this.sending = false;
		this.okStatus = false;
		this.errorStatus = false;

		this.tipo = null;
		this.titulo = null;
		this.categoria = null;
		this.descripcion = null;
		this.campos = [];
		this.visto = null;
		this.considerando = null;
		this.articulado = null;

		this.secgralService.getCategorias()
		.subscribe((data:any) => {
			this.categorias = data.categorias;
		}, error => {
			console.log(error);
		});

	}

	ngOnInit() {
	}

	agregarCampo(){
		(<any>$('.collapse.show')).collapse();
		this.campos.push({titulo: '', selector: '', default: '', hide: false});
	}

	eliminarCampo(i){
		this.campos.splice(i, 1);
	}

	guardar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.modelo = new Modelo(null, this.tipo, this.titulo, this.categoria, this.descripcion, JSON.stringify({campos:this.campos}), this.visto, this.considerando, this.articulado, null, null);
		this.secgralService.newModelo(this.modelo)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			setTimeout(() => {		
				this.router.navigate(['/modelos']);
			}, 3000);
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
	}

	insertarVisto($event){
		let vistoRes = "El Expediente N° ##expediente## del registro de este Ministerio; y";
		let vistoDec = "El Expediente N° ##expediente## del registro del Ministerio de Salud Pública; y";
		if ($event.target.value == 1) {
			if (!this.visto || this.visto == vistoDec) {
				this.visto = vistoRes;
			}
		}
		else if ($event.target.value == 2) {
			if (!this.visto || this.visto == vistoRes) {
				this.visto = vistoDec;
			}
		}
		
	}

	copyText(val){
		$('.copyMsg').removeClass('hide');

		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		setTimeout(() => {
			$('.copyMsg').addClass('hide');
		}, 1200);
	}

	drop(event: CdkDragDrop<any>) {
		moveItemInArray(this.campos, event.previousIndex, event.currentIndex);
	}

	moverCampo(prevIndex, newIndex) {
		if (newIndex > -1 && newIndex < this.campos.length) {
			let removedElement = this.campos.splice(prevIndex, 1);
			this.campos.splice(newIndex, 0, removedElement[0]);
		}
	}

}
