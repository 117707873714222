import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModeloPase  } from '../../models/modelo-pase.model';
import { SecgralService } from '../../services/secgral.service';

@Component({
	selector: 'app-new-modelo-pase',
	templateUrl: './new-modelo-pase.component.html',
	styleUrls: ['./new-modelo-pase.component.css']
})

export class NewModeloPaseComponent implements OnInit {
	
	modeloPase: any;
	areas: any;
	nombreCompletoPase: string;
	areaTexto: string;
	editar:boolean;
	idEditar:any;

	loading: boolean = false;
	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private secgralService:SecgralService
		) {

		this.sending = false;
		this.okStatus = false;
		this.errorStatus = false;

		this.editar = false;
		this.idEditar = null;
		this.nombreCompletoPase = "";
		this.areaTexto = "";
		this.modeloPase = new ModeloPase(null, null, null, null, null, 2, null, null, null, null);
		
		this.route.paramMap
		.subscribe(params => {
			this.idEditar = params.get("id");
			if (this.idEditar) {
				this.editar = true;
				this.getModelo(this.idEditar);
			}
			console.log(this.idEditar);
		});

		this.secgralService.getAreas()
		.subscribe((data:any) => {
			console.log(data);
			this.areas = data.areas;
		}, error => {
			console.log(error);
		});

	}

	ngOnInit() {

	}

	getModelo(id){
		this.loading = true;
		this.secgralService.getModeloPase(id)
		.subscribe( (data:any) => {
			//console.log(data.modeloPase);
			this.modeloPase = new ModeloPase(null, data.modeloPase.tipo, data.modeloPase.referente, data.modeloPase.destinatario, data.modeloPase.area_id, 2, data.modeloPase.titulo, data.modeloPase.contenido, null, null);
			this.loading = false;
		}, error => {
			console.log(error);
		});
	}

	guardar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		if (!this.editar) {
			let modeloPaseAEnviar = new ModeloPase(null, this.modeloPase.tipo, this.modeloPase.referente, this.modeloPase.destinatario, this.modeloPase.area_id, 2, this.cargarNombreCompletoPase().toUpperCase(), this.modeloPase.contenido, null, null);
			console.log(modeloPaseAEnviar);

			this.secgralService.newModeloPase(modeloPaseAEnviar)
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				//console.log(data);
				setTimeout(() => {
					this.router.navigate(['/pases']);
				}, 3000); 
			}, error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			});
		}
		else{
			delete this.modeloPase.id;
			delete this.modeloPase.destino;
			delete this.modeloPase.user
			console.log(this.modeloPase);
			this.secgralService.editModeloPase(this.idEditar, this.modeloPase)
			.subscribe((data:any) => {
				this.sending = false;
				this.okStatus = true;

				//console.log(data);
				setTimeout(() => {
					this.router.navigate(['/pases']);
				}, 3000);
			}, error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			})
		}
	}

	eliminar(id){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		console.log(id);
		this.secgralService.delModeloPase(this.idEditar)
		.subscribe((data:any) => {
			this.sending = false;
			this.okStatus = true;

			//console.log(data);
			(<any>$('#deleteModal')).modal('hide');
			setTimeout(() => {
				this.router.navigate(['/pases']);
			}, 3000);
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		})
	}

	seleccionArea(area){
		this.areaTexto = area.target.selectedOptions[0].outerText;
	}

	cargarNombreCompletoPase(){
		return "A: " + this.areaTexto + " - " + this.modeloPase.titulo;
	}
}
