import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { NormaLegal } from '../../models/norma-legal.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-normas',
  templateUrl: './normas.component.html',
  styleUrls: ['./normas.component.css']
})
export class NormasComponent implements OnInit {

	fecha: Date;
	normaLegal: NormaLegal;
	numRes: number;
	num_expte: string;
	detalle: string;
	numeroDisponible: boolean;
	validandoNumRes: boolean;

	numerando: boolean;
	numerarStatus: boolean;
	errorStatus: boolean;
	errorMsg: string;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private router: Router,
				private secgralService: SecgralService,
				private auth: AuthService) {

	this.auth.isActive();

	this.fecha = new Date();
	this.numRes = null;
	this.num_expte = null;
	this.detalle = null;
	this.numeroDisponible = true;
	this.validandoNumRes = false;

	this.numerando = false;
	this.numerarStatus = false;
	this.errorStatus = false;
	this.errorMsg = null;
	
	this.normaLegal = new NormaLegal(null, 1, this.numRes, this.fecha, this.num_expte, 2, null, true, this.detalle, null, null, null, null, null);

	this.secgralService.getNumeroResDisponible(this.fecha.getFullYear())
	.subscribe( ( data:any ) => {
		
		if (data.ultima) {
			this.normaLegal.numero = data.ultima.numero + 1;
		}
		else{
			this.normaLegal.numero = 1;
		}

	}, error => {
		console.log(error);
	});
  }

  ngOnInit() {
  }

	checkNumDisponible(e){
		if (e.target.value) {
			let newYear = (new Date(e.target.value)).getFullYear();

			if (newYear != this.fecha.getFullYear()) {
				this.actualizarNumDisponible(newYear);
			}

			this.fecha = new Date(e.target.value);
		}
	}

	checkNumeroDisponible(){
		this.validandoNumRes = true;
		if (this.normaLegal.numero){
			let tipo = 'resoluciones';			
			this.secgralService.checkNumDisponible(tipo, this.normaLegal.numero, this.fecha.getFullYear())
			.subscribe( (data:any) => {
				//console.log(data);
				if (data.result.length == 0) {
					//console.log("NUMERO DISPONIBLE");
					this.numeroDisponible = true;
				}else{
					//console.log("NUMERO NO DISPONIBLE");
					this.numeroDisponible = false;
				}
				this.validandoNumRes = false;
			}, error => {
				console.log(error);
			});	
		}
	}

  actualizarNumDisponible(year){
	this.secgralService.getNumeroResDisponible(year)
	.subscribe( ( data:any ) => {
		console.log(data);
		if(data.ultima){
			this.numRes = data.ultima.numero + 1;
			console.log(this.numRes);
		}
		else{
			this.numRes = null;
		}
	}, error => {
		console.log(error);
	});
  }

	reservarResolucion(){
		this.numerando = true;
		this.errorStatus = false;

		console.log(this.normaLegal);
		this.secgralService.newNorma(this.normaLegal)
		.subscribe( (data:any) => {
			console.log(data);
			this.numerando = false;
			this.numerarStatus = true;
			setTimeout(() => {
				(<any>$('#reservarModal')).modal('hide');
				this.onRefresh(); 
			}, 2000);
		}, error => {
			this.numerando = false;
			this.errorStatus = true;
			this.checkNumeroDisponible();
			console.log(error);
		});
	}

  onRefresh() {
	this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

	let currentUrl = this.router.url + '?';

	this.router.navigateByUrl(currentUrl)
	.then(() => {
	  this.router.navigated = false;
	  this.router.navigate([this.router.url]);
	});
  }

}
