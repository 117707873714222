import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	username: string = null;
	token: any;
  	filtroTipo: string;

	constructor(private auth: AuthService) {
	}

	ngOnInit() {
		$('.navItem .dropdown-toggle').on('mouseenter', function () {
			if (!$(this).parent().hasClass('show')) {
				$(this).siblings( ".dropdown-menu" ).dropdown('toggle');
			}
		});
		

		$('.btn-group, .dropdown').on('mouseleave', function () {
			if ($(this).hasClass('show')){
				$(this).children(".dropdown-menu").dropdown('hide');
			}
		});

		$('.dropdown-item').on('click', function () {
			$('.navbar-collapse').collapse('hide');
		});

		this.username = localStorage.getItem('username');
	}

	logout(){
		this.auth.logout();
	}
  

}
