import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { NumeroALetrasService } from '../../services/numero-aletras.service';
import { ImprimirService } from '../../services/imprimir.service';
import { NormaLegal } from '../../models/norma-legal.model';

declare var jQuery: any;
declare var $: any;

@Component({
	selector: 'app-new-norma-multiple',
	templateUrl: './new-norma-multiple.component.html',
	styleUrls: ['./new-norma-multiple.component.css']
})
export class NewNormaMultipleComponent implements OnInit {

	normaLegal: NormaLegal;
	tipo: any;
	id: any;
	modelo: any;

	visto: string;
	considerando: string;
	articulado: string;

	considerandoArray: any;
	articuladoArray: any;

	campos: any;
	arrayCampos: any;
	arrayNormas: any;
	arrayNormasGeneradas: any;
	stage: number;

	sending: boolean;
	finish: boolean;

	autoUpper: boolean = false;

	sended: number;
	saved: number;
	error: number;
	done: number;

	pageSize: string;
	fontSize: number;
	margenSuperior: number;
	margenInferior: number;
	margenDerecho: number;
	margenIzquierdo: number;
	indent: number;
	header: boolean;
	negrita: number;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
		private secgralService: SecgralService,
		private conversor: NumeroALetrasService,
		private imprimir: ImprimirService
	) {
		this.stage = 1;
		this.sending = false;
		this.finish = false;
		this.sended = 0;
		this.saved = 0;
		this.error = 0;
		this.done = 0;

		this.modelo = null;
		this.arrayCampos = [];
		this.arrayNormas = [];
		this.arrayNormasGeneradas = [];

		this.considerandoArray = [];
		this.articuladoArray = [];

		this.route.paramMap.subscribe(params => {
			this.tipo = params.get("tipo");
			if (this.tipo == "resolucion" || this.tipo == "resolución") {
				this.tipo = 1;
			}
			else if (this.tipo == "decreto") {
				this.tipo = 2;
			}
			this.id = params.get("modeloId");

			this.getModeloNorma();
			this.defaultPrintValues();
		});
	}

	ngOnInit() {
	}

	getModeloNorma(){
		this.secgralService.getModelo(this.id)
		.subscribe( (data:any) => {
			this.modelo = data.modelo;
			this.visto = this.modelo.visto;
			this.considerando = this.modelo.considerando;
			this.articulado = this.modelo.articulado;

			this.campos = JSON.parse(this.modelo.campos);
			this.campos.campos.unshift({titulo: "Numero de Expediente", selector: "expediente", default: ""});

			this.agregarCampos();
		}); 
	}

	agregarCampos(){
		$('#accordion .collapse').collapse('hide');

		let grupoDeCamposAgregar = [];
		for (var i = 0; i < this.campos.campos.length; i++) {
			grupoDeCamposAgregar.push({titulo: this.campos.campos[i].titulo, selector: this.campos.campos[i].selector, default: this.campos.campos[i].default})
		}
		this.arrayCampos.push(grupoDeCamposAgregar);

		this.agregarNormaLegal();

		setTimeout(() => {
			$('#accordion > .card:last-child .collapse').collapse('show');
		}, 500);
		
	}

	agregarNormaLegal(){
		this.arrayNormas.push(this.normaLegal = new NormaLegal(null, this.tipo, null, null, null, 2, this.id, false, null, this.visto, this.considerando, this.articulado, null, null));
		
		this.reemplazarCamposModelo(this.arrayNormas.length - 1);
	}

	guardar(){
		this.sending = true;
		this.sended = 0;
		this.saved = 0;
		this.error = 0;
		this.done = 0;

		for (var i = 0; i < this.arrayNormas.length; i++) {
			delete this.arrayNormas[i].considerandoArray;
			delete this.arrayNormas[i].articuladoArray;
			
			this.secgralService.newNorma(this.arrayNormas[i])
			.subscribe( (data:any) => {
				
				this.arrayNormasGeneradas.push(data.normaLegal);
				this.sended++;
				this.saved++;
				if (this.sended == this.arrayNormas.length) {
					setTimeout(() => {
						this.finish = true;
						this.sending = false;		
					}, 1500);
				}

				this.done = ( this.saved / this.arrayNormas.length ) * 100;
			}, error => {
				console.log(error);
				this.error++;

				this.sended++;
				if (this.sended == this.arrayNormas.length) {
					setTimeout(() => {
						this.finish = true;
						this.sending = false;		
					}, 1500);
				}

			});
		}
	}

	vistaPrevia(){
		this.stage = 2;
	}

	reemplazarCampos(){
		for(var i = 0; i < this.arrayCampos.length; i++){
			this.reemplazarCamposModelo(i);
		}
	}

	reemplazarCamposModelo(i){
		this.arrayNormas[i].visto = this.visto;
		this.arrayNormas[i].considerando = this.considerando;
		this.arrayNormas[i].articulado = this.articulado;

		for (var k = 0; k < this.arrayCampos[i].length; k++) {
			
			//	CARGAR NUMERO DE EXPEDIENTE A LA NORMA LEGAL
			if (this.arrayCampos[i][k].selector == "expediente" && this.arrayCampos[i][k].default) {
				this.arrayNormas[i].num_expte = this.arrayCampos[i][k].default;
			}

			if (this.arrayCampos[i][k].selector.indexOf("en letras: ") == 0) {
				let posicion = this.arrayCampos[i][k].selector.indexOf(":") + 1;
				let campoNumero = this.arrayCampos[i][k].selector.substring(posicion).trim();
				let valor = null;
				for (let c of this.arrayCampos[i]) {
					if (c.selector == campoNumero) {
						valor = c.default;
					}
				}
				valor = valor.split(".").join("").split(",").join(".");
				if (this.conversor.numeroALetras(Number(valor)).indexOf("undefined") == -1) {
					this.arrayCampos[i][k].default = this.conversor.numeroALetras(Number(valor));
				} else {
					this.arrayCampos[i][k].default = "";
				}
			}

			let value = this.autoUpper ? this.arrayCampos[i][k].default.toUpperCase() : this.arrayCampos[i][k].default;
			value = this.arrayCampos[i][k].default != "" ? value : "(" + this.arrayCampos[i][k].titulo.toUpperCase() + ")";

			//	REEMPLAZAR LOS SELECTORES POR SUS VALORES
			this.arrayNormas[i].visto = this.arrayNormas[i].visto ? this.arrayNormas[i].visto
				.split("##" + this.arrayCampos[i][k].selector + "##")
				.join(value) : null;

			this.arrayNormas[i].considerando = this.arrayNormas[i].considerando ? this.arrayNormas[i].considerando
				.split("##" + this.arrayCampos[i][k].selector + "##")
				.join(value) : null;

			this.arrayNormas[i].articulado = this.arrayNormas[i].articulado ? this.arrayNormas[i].articulado
				.split("##" + this.arrayCampos[i][k].selector + "##")
				.join(value) : null;
			
		}
		
		this.separarParrafos(i);
		
	}

	separarParrafos(i) {
		this.considerandoArray[i] = this.arrayNormas[i].considerando ? this.arrayNormas[i].considerando.split("\n") : null;
		this.articuladoArray[i] = this.arrayNormas[i].articulado ? this.arrayNormas[i].articulado.split("\n") : null;
	}

	eliminarCampo(i){
		this.arrayCampos.splice(i, 1);
		this.arrayNormas.splice(i, 1);
	}

	defaultPrintValues() {
		this.pageSize = "LEGAL";
		this.margenDerecho = 2;
		this.fontSize = 12;
		this.header = true;

		if (this.tipo == 1) {
			this.margenSuperior = 6;
			this.margenInferior = 3;
			this.margenIzquierdo = 4;
			this.negrita = 0;
			this.indent = 60;
		}
		else if (this.tipo == 2) {
			this.margenSuperior = 8;
			this.margenInferior = 6;
			this.margenIzquierdo = 5;
			this.negrita = 3;
			this.indent = 90;
		}
	}

	generarPDFs() {
		let params = {
			pageSize: this.pageSize,
			fontSize: this.fontSize,
			margenSuperior: this.margenSuperior,
			margenInferior: this.margenInferior,
			margenDerecho: this.margenDerecho,
			margenIzquierdo: this.margenIzquierdo,
			indent: this.indent,
			header: this.header,
			negrita: this.negrita,
		};
		for (let normaGenerada of this.arrayNormasGeneradas) {
			this.imprimir.generarPDF(normaGenerada, params);
		}
	}
}
