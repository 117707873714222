export class Modelo {
	constructor(
		public id:number,
		public tipo: any,
		public titulo: string,
		public categoria: string,
		public descripcion: string,
		public campos: string,
		public visto: string,
		public considerando: string,
		public articulado: string,
		public created_at: any,
		public updated_at: any
	){}
}
