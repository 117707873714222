import { Pipe, PipeTransform } from '@angular/core';
import { NormaLegal } from '../models/norma-legal.model';

@Pipe({
	name: 'normaContenidoFilter'
})
export class NormaContenidoFilterPipe implements PipeTransform {

	transform(normasLegales: any[], searchTerm: string): NormaLegal[] {

		if (!normasLegales || !searchTerm) {
			return normasLegales;
		}
		return normasLegales.filter( normaLegal => {
			if (normaLegal.visto && normaLegal.visto.indexOf(searchTerm.toLowerCase()) !== -1) {
				return true;
			}
			else if(normaLegal.considerando && normaLegal.considerando.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1){
				return true;
			}
			else if (normaLegal.articulado && normaLegal.articulado.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1){
				return true;
			}
			else{
				return false;
			}
		}); 	
	}
}
