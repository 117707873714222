import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-expedientes',
  templateUrl: './modal-expedientes.component.html',
  styleUrls: ['./modal-expedientes.component.css']
})
export class ModalExpedientesComponent implements OnInit {

	fecha: Date;

  constructor() {
  	this.fecha = new Date();
  }

  ngOnInit() {
  }

  onSubmit(ingresoExpteForm){
  	
  }

}
