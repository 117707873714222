import { Component, OnInit, Output, EventEmitter,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Expediente } from '../../models/expediente.model';
import { SecgralService } from '../../services/secgral.service';

declare var jQuery: any;
declare var $: any;

@Component({
	selector: 'app-tabla-expedientes',
	templateUrl: './tabla-expedientes.component.html',
	styleUrls: ['./tabla-expedientes.component.css']
})
export class TablaExpedientesComponent implements OnInit {
	@Output() public listadoExpedientes = new EventEmitter();
	fecha: Date;
	expedienteAReingresar: Expediente;
	expedientes: any;
	
	movimientoFilter: string;
	searchTerm: string;
	pageActual: number = 1;
	loading: boolean;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private router: Router,
				private route: ActivatedRoute,
				private secgralService: SecgralService){
		
		this.fecha = new Date();
		this.expedientes = [];

		this.movimientoFilter = null;
		this.loading = true;

		this.route.paramMap.subscribe(params => {
			this.movimientoFilter = params.get("tipo");
		});

		this.secgralService.getExpedientes()
		.subscribe( (data:any) => {
			//console.log(data.expedientes);
			this.expedientes = data.expedientes;

			this.listadoExpedientes.emit(data.expedientes);
			this.loading = false;
		}, error => {
			console.log(error);
		});
		
		$(function () {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}

	ngOnInit() {
	}

	reingresarExpediente(expediente){

		this.expedienteAReingresar = JSON.parse(JSON.stringify(expediente));;
		this.expedienteAReingresar.fecha = this.fecha;
		this.expedienteAReingresar.area_salida = null;
		this.expedienteAReingresar.created_at = null;
		this.expedienteAReingresar.updated_at = null;

		console.log(this.expedienteAReingresar);

		this.secgralService.inExpediente(this.expedienteAReingresar)
		.subscribe(response => {
			console.log(response);
			this.onRefresh();
		}, error => {
			console.log(error);
		});
	}

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
		.then(() => {
			this.router.navigated = false;
			this.router.navigate([this.router.url]);
		});
	}

}
