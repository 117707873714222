import { Pipe, PipeTransform } from '@angular/core';
import { NormaLegal } from '../models/norma-legal.model';

@Pipe({
  name: 'numeroNormaFilter'
})
export class NumeroNormaFilterPipe implements PipeTransform {

  transform(normasLegales: any[], searchTermNumero: number): NormaLegal[] {

    if (!normasLegales || !searchTermNumero) {
      return normasLegales;
    }
    return normasLegales.filter(normaLegal => {
      if (normaLegal.numero && normaLegal.numero == searchTermNumero) {
        return true;
      }
      else {
        return false;
      }
    });
  }

}
