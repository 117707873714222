export class Expediente {
	constructor(
		public id:number,
		public fecha: any,
		public area_salida: any,
		public user_id: any,
		public num_expte: string,
		public extracto: string,
		public created_at: any,
		public updated_at: any
	){}
}
