import { Component, OnInit } from '@angular/core';
import { SecgralService } from '../../services/secgral.service';
import { AuthService } from 'src/app/services/auth.service';

declare var jQuery: any;
declare var $: any;

const defaultQueryParams: any = {
	page: 1,
	tipo: null,
	numero: null,
	expediente: null,
	descripcion: null,
	contenido: null,
	reservado: null,
	numerado: null,
	full: true,
}

@Component({
	selector: 'app-busqueda-avanzada',
	templateUrl: './busqueda-avanzada.component.html',
	styleUrls: ['./busqueda-avanzada.component.css']
})
export class BusquedaAvanzadaComponent implements OnInit {

	loading:boolean = false;

	queryParams: any;
	tipoSelected: number;
	numeradoSelected: string;
	reservadoSelected: string;
	numeroSearch: number;
	expedienteSearch: string;
	descripcionSearch: string;
	contenidoSearch: string;

	numeradoFilter: string = 'todo';
	tipoFilter: string = 'todo';
	searchTermExpediente: string = null;
	searchTermModelo: string = null;
	searchTermContenido: string = null;

	normas: any = [];
	normaSeleccionada: any = null;
	considerandoArray: any = [];
	articuladoArray: any = [];

	constructor(private secgralService:SecgralService,
				private auth: AuthService) {
		this.queryParams = { ...defaultQueryParams };
		this.tipoSelected = null;
		this.numeradoSelected = null;
		this.reservadoSelected = null;
		this.numeroSearch = null;
		this.expedienteSearch = null;
		this.descripcionSearch = null;
		this.contenidoSearch = null;

		this.auth.isActive();
		this.buscarNormas();
	}

	ngOnInit() {
	}

	getNormasCompletas(){
		this.loading = true;
		this.secgralService.getNormasCompletas()
		.subscribe( (data:any) => {
			this.normas = data.normalegal;
			this.loading = false;
		}, error => {
			this.loading = false;
			console.log(error);
		});
	}

	limpiarFiltros(){
		this.numeradoFilter = 'todo';
		this.tipoFilter = 'todo';
		this.searchTermExpediente = null;
		this.searchTermModelo = null;
		this.searchTermContenido = null;
	}

	separarParrafos(){
		this.considerandoArray = this.normaSeleccionada.considerando ? this.normaSeleccionada.considerando.split("\n") : [];
		this.articuladoArray = this.normaSeleccionada.articulado ? this.normaSeleccionada.articulado.split("\n") : [];
	}

	seleccionarNorma(norma){
		this.normaSeleccionada = norma;

		this.separarParrafos();
		this.mostrarModal();
	}

	mostrarModal(){
		if (window.innerWidth < 768){
			$('#detalleModal').modal('show');
		}
	}

	cerrarModal() {
		$('#detalleModal').modal('hide');
	}

	cleanParams() {
		let cleanParams = {};
		for (var propName in this.queryParams) {
			if (this.queryParams[propName] && this.queryParams[propName] !== null && this.queryParams[propName] !== "") {
				cleanParams[propName] = this.queryParams[propName];
			}
		}
		return cleanParams;
	}

	buscarNormas(resetPaging = true) {
		this.loading = true;
		this.normas = [];
		if (resetPaging) {
			this.queryParams.page = 1;
		}
		this.secgralService.normasSearch(this.cleanParams())
			.subscribe((data: any) => {
				this.normas = data.normalegal;
				this.loading = false;
			});
	}

	resetSearchParams() {
		this.tipoSelected = null;
		this.numeradoSelected = null;
		this.reservadoSelected = null;
		this.numeroSearch = null;
		this.expedienteSearch = null;
		this.descripcionSearch = null;
		this.contenidoSearch = null;
		this.queryParams = { ...defaultQueryParams };
		this.buscarNormas();
	}

	buscarNormasPrimeraPagina() {
		if (this.queryParams.page > 1) {
			this.queryParams.page = 1;
			this.buscarNormas();
		}
	}

	buscarNormasAnteriorPagina() {
		if (this.queryParams.page > 1) {
			this.queryParams.page = this.queryParams.page - 1;
			this.buscarNormas(false);
		}
	}

	buscarNormasProximaPagina() {
		this.queryParams.page = this.queryParams.page + 1;
		this.buscarNormas(false);
	}

	handletipoSelectedChange(tipo) {
		this.tipoSelected = tipo;
		this.queryParams.tipo = tipo;
		this.buscarNormas();
	}

	handleNumeradoSelectedChange(numerado) {
		this.numeradoSelected = numerado;
		this.queryParams.numerado = numerado;
		this.buscarNormas();
	}

	handleReservadoSelectedChange(reservado) {
		this.reservadoSelected = reservado;
		this.queryParams.reservado = reservado;
		this.buscarNormas();
	}

	handleSearchButton() {
		this.queryParams.numero = this.numeroSearch;
		this.queryParams.expediente = this.expedienteSearch;
		this.queryParams.descripcion = this.descripcionSearch;
		this.queryParams.contenido = this.contenidoSearch;
		this.buscarNormas();
	}
}
