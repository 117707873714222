import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SecgralService {

	//apiUrl: string = 'http://localhost/secgralapi/public/api/';
	apiUrl: string = 'https://api.secgralmsp.com/public/api/';

	constructor(private http: HttpClient,
				private auth: AuthService) {
		this.auth.isActive();
	}

	newUser(user){
		console.log(user);
		let json = JSON.stringify(user);
		let params = 'json=' + json;
		
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'register', params, {headers: headers});
	}

	editUser(id, user){
		let json = JSON.stringify(user);
		let params = 'json=' + json;
		
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'user/' + id, params, {headers: headers});
	}

	changePassword(id, pass){
		let json = JSON.stringify(pass);
		let params = 'json=' + json;
		
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'user/changepassword/' + id, params, {headers: headers});
	}

	desactivarUser(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'user/' + id, {headers: headers});
	}

	activarUser(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'user/' + id, {headers: headers});
	}

	getUsers(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'userslist', {headers: headers});
	}

	getNormas(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'normaslegales/search', {headers: headers});
	}

	normasSearch(paramsObject) {
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(`${this.apiUrl}normaslegales/search?${stringify(paramsObject)}`, { headers: headers });
	}

	getNormasCompletas(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'normaslegalescompletas', {headers: headers});
	}

	getResoluciones(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'resoluciones', {headers: headers});
	}

	getDecretos(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'decretos', {headers: headers});
	}

	getNumeroResDisponible(year){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'resoluciones/ultima/' + year, {headers: headers});
	}

	checkNumDisponible(tipo, numero, year){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'checknum/' + tipo +  '/' + numero + '/' + year, {headers: headers});
	}

	getNorma(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'normaslegales/' + id, {headers: headers});
	}

	newNorma(norma){
		let json = JSON.stringify(norma);
		let params = 'json=' + json.replace(/&/g, "%26");

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'normaslegales', params, {headers: headers});
	}

	editNorma(id, norma){
		let json = JSON.stringify(norma);
		let params = 'json=' + json.replace(/&/g, "%26");
		
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'normaslegales/' + id, params, {headers: headers});
	}

	delNorma(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'normaslegales/' + id, {headers: headers});
	}

	getExpedientes(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'expedientes', {headers: headers});
	}

	getExpediente(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'expedientes/' + id, {headers: headers});
	}

	inExpediente(expediente){
		let json = JSON.stringify(expediente);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'expedientes', params, {headers: headers});
	}

	editExpediente(id, expediente){
		let json = JSON.stringify(expediente);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'expedientes/' + id, params, {headers: headers});
	}

	delExpediente(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'expedientes/' + id, {headers: headers});
	}

	newPase(pase){
		let json = JSON.stringify(pase);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'pases', params, {headers: headers});
	}

	getPases(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'pases', {headers: headers});
	}

	getPase(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'pases/' + id, {headers: headers});
	}

	editPase(id, pase){
		let json = JSON.stringify(pase);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'pases/' + id, params, {headers: headers});
	}

	delPase(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'pases/' + id, {headers: headers});
	}

	newContenidoPase(contenidoPase) {
		let json = JSON.stringify(contenidoPase);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post(this.apiUrl + 'contenidopases', params, { headers: headers });
	}

	editContenidoPase(id, contenidoPase){
		let json = JSON.stringify(contenidoPase);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'contenidopases/' + id, params, {headers: headers});
	}

	delContenidoPase(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'contenidopases/' + id, {headers: headers});
	}

	getNumeroPaseDisponible(year){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'pases/ultimo/infome/' + year, {headers: headers});
	}

	getNumeroMemoDisponible(year){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'pases/ultimo/memo/' + year, {headers: headers});
	}

	getModelosPases(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'modelospases', {headers: headers});
	}

	getModeloPase(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'modelospases/' + id, {headers: headers});
	}

	newModeloPase(modeloPase){
		let json = JSON.stringify(modeloPase);
		let params = 'json=' + json;
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(this.apiUrl + 'modelospases', params, {headers: headers});
	}

	editModeloPase(id, modeloPase){
		let json = JSON.stringify(modeloPase);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'modelospases/' + id, params, {headers: headers});
	}

	delModeloPase(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'modelospases/' + id, {headers: headers});
	}
	
	getModelos(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'modelos', {headers: headers});
	}

	getModelo(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'modelos/' + id, {headers: headers});
	}

	newModelo(modelo){
		let json = JSON.stringify(modelo);
		let params = 'json=' + json;
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(this.apiUrl + 'modelos', params, {headers: headers});
	}

	editModelo(id, modelo){
		let json = JSON.stringify(modelo);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'modelos/' + id, params, {headers: headers});
	}

	delModelo(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'modelos/' + id, {headers: headers});
	}

	getTareas(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'tareas', {headers: headers});
	}

	getTarea(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'tareas/' + id, {headers: headers});
	}

	getAreas(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'areas', {headers: headers});
	}

	newArea(area){
		let json = JSON.stringify(area);
		let params = 'json=' + json;
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(this.apiUrl + 'areas', params, {headers: headers});
	}

	editArea(id, area){
		let json = JSON.stringify(area);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'areas/' + id, params, {headers: headers});
	}

	delArea(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'areas/' + id, {headers: headers});
	}

	getCategorias(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'categorias', {headers: headers});
	}

	newCategoria(categoria){
		let json = JSON.stringify(categoria);
		let params = 'json=' + json;
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(this.apiUrl + 'categorias', params, {headers: headers});
	}

	editCategoria(id, categoria){
		let json = JSON.stringify(categoria);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.put(this.apiUrl + 'categorias/' + id, params, {headers: headers});
	}

	delCategoria(id){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.delete(this.apiUrl + 'categorias/' + id, {headers: headers});
	}

}
