import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { Expediente } from '../../models/expediente.model';

@Component({
	selector: 'app-editar-expedientes',
	templateUrl: './editar-expedientes.component.html',
	styleUrls: ['./editar-expedientes.component.css']
})
export class EditarExpedientesComponent implements OnInit {

		id: string;

		movimientos;
		fecha: Date;
		expediente: Expediente = null;
		anteriorNum: string;
		pases:any;

		sending: boolean;
		okStatus: boolean;
		errorStatus: boolean;

		nivelPermisos: any = localStorage.getItem('access');

	constructor(private location: Location,
							private route: ActivatedRoute,
							private router: Router,
							private secgralService: SecgralService) {
								this.route.paramMap.subscribe(params => {
									this.id = params.get("id")
								});

		this.sending = false;
		this.okStatus = false;
		this.errorStatus = false;
	
		this.fecha = new Date();
		this.pases = [];
		
		this.errorStatus = false;
		
		this.secgralService.getExpediente(this.id)
			.subscribe( (data:any) => {
				this.expediente = new Expediente(null, this.fecha, null, 2, '', '', '', '');
				this.expediente.id = data.expediente.id;
				this.expediente.fecha = data.expediente.fecha;
				this.expediente.num_expte = data.expediente.num_expte;
				this.anteriorNum = data.expediente.num_expte;
				this.expediente.extracto = data.expediente.extracto;
				this.movimientos = data.movimientos;
				if (data.expediente.pases) {
					this.pases = data.expediente.pases;
				}
		});

	}

	ngOnInit() {
	}

	editarExpediente(id){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.secgralService.editExpediente(id, this.expediente)
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				setTimeout(() => {
					this.router.navigate(['/expedientes', id]);
				}, 3000); 
			},
			error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			});
	}

	eliminarExpediente(id){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		/*SI TIENE UN PASE ASOCIADO, ELIMINA LA FK DEL PASE ANTES DE ELIMINAR EL MOVIMIENTO DE EXPTE*/
		if (this.pases.length > 0) {
			let pase = this.pases[0];
			pase.expediente_id = null;
			this.secgralService.editPase(pase.id, pase)
			.subscribe( (data:any) => {
					this.secgralService.delExpediente(id)
					.subscribe( (data:any) => {
						this.sending = false;
						this.okStatus = true;

						this.router.navigate(['/expedientes']);
					},
					error => {
						this.sending = false;
						this.errorStatus = true;

						console.log(error);
					});
					(<any>$('#deleteModal')).modal('hide');
				}, error => {
					this.sending = false;
					this.errorStatus = true;

					console.log(error);
			});
		}
		else{
			/*SI NO TIENE PASE ASOCIADO, LO ELIMINA DIRECTAMENTE*/
			this.secgralService.delExpediente(id)
				.subscribe( (data:any) => {
					this.sending = false;
					this.okStatus = true;

					(<any>$('#deleteModal')).modal('hide');
					this.router.navigate(['/expedientes']);
				},
				error => {
					console.log(error);
					this.errorStatus = true;
				});
		}
	}

	atras(){
		this.location.back();
	}

}
