import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { SecgralService } from '../../services/secgral.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-config',
	templateUrl: './config.component.html',
	styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

	user:User = new User(null, null, null, null, null);
	username: string = null;;
	email: string = null;;
	userId: any = null;;
	permisos: any = null;;
	newPass: string;


	sending: boolean = false;
	okStatus: boolean = false;
	errorStatus: boolean = false;
	nueva:boolean = false;
	nuevoUser: boolean = false;

	categorias:any = [];
	categoriaSeleccionadaNombre:any = null;;
	categoriaSeleccionadaNumero:any = null;;
	categoriaAEditar:any = null;;

	areas:any = [];
	areaSeleccionadaNombre:any = null;
	areaSeleccionadaNumero:any = null;
	areaAEditar:any = null;

	users:any = null;
	userSeleccionado:any = null;
	userSelecionadoNombre:any = null;
	userSelecionadoEmail:any = null;
	userSelecionadoId:any = null;

	userAEditar:User = new User(null, null, null, null, null);

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private secgralService:SecgralService,
				private auth: AuthService) {

		this.auth.isActive();

		this.cargarUsuario();

		this.cargarUsuarios();

		this.cargarAreas();

		this.cargarCategorias();


	}

	ngOnInit() {
		$('#areasModal, #categoriasModal').on('hidden.bs.modal',  (e) => {
			this.okStatus = false;
			this.errorStatus = false;
			this.nueva = false;
		});
	}

	seleccionarCategoria(e){
		this.categoriaSeleccionadaNombre = this.categorias[e.target.value].categoria;
		this.categoriaSeleccionadaNumero = this.categorias[e.target.value].id;
		this.categoriaAEditar = this.categoriaSeleccionadaNombre;
	}

	seleccionarArea(e){
		this.areaSeleccionadaNombre = this.areas[e.target.value].area;
		this.areaSeleccionadaNumero = this.areas[e.target.value].id;
		this.areaAEditar = this.areaSeleccionadaNombre;
	}

	editarCategoria(){
		if (this.nueva) {
			this.sending = true;
			this.errorStatus = false;
			this.secgralService.newCategoria({categoria: this.categoriaAEditar})
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				this.cargarCategorias();
				setTimeout(() => {
					(<any>$('#categoriasModal')).modal('hide');
					this.okStatus = false;
					this.nueva = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sending = false;
				this.errorStatus = true;
			});
		}
		else {
			this.sending = true;
			this.errorStatus = false;
			this.secgralService.editCategoria(this.categoriaSeleccionadaNumero, {categoria: this.categoriaAEditar})
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				this.cargarCategorias();
				setTimeout(() => {
					(<any>$('#categoriasModal')).modal('hide');
					this.okStatus = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sending = false;
				this.errorStatus = true;
			});
		}
	}


	eliminarCategoria(){
		this.sending = true;
		this.errorStatus = false;

		this.secgralService.delCategoria(this.categoriaSeleccionadaNumero)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarCategorias();
			setTimeout(() => {
				(<any>$('#categoriasModal')).modal('hide');
				this.okStatus = false;
			}, 2000);
		}, error => {
			console.log(error);
			this.sending = false;
			this.errorStatus = true;
		});
	}

	editarArea(){
		if (this.nueva) {
			this.sending = true;
			this.errorStatus = false;
			this.secgralService.newArea({area: this.areaAEditar})
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				this.cargarAreas();
				setTimeout(() => {
					(<any>$('#areasModal')).modal('hide');
					this.okStatus = false;
					this.nueva = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sending = false;
				this.errorStatus = true;
			});
		}
		else {
			this.sending = true;
			this.errorStatus = false;
			this.secgralService.editArea(this.areaSeleccionadaNumero, {area: this.areaAEditar})
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;

				this.cargarAreas();
				setTimeout(() => {
					(<any>$('#areasModal')).modal('hide');
					this.okStatus = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sending = false;
				this.errorStatus = true;
			});
		}
	}


	eliminarArea(){
		this.sending = true;
		this.errorStatus = false;
		this.secgralService.delArea(this.areaSeleccionadaNumero)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarAreas();
			setTimeout(() => {
				(<any>$('#areasModal')).modal('hide');
				this.okStatus = false;
			}, 2000);
		}, error => {
			console.log(error);
			this.sending = false;
			this.errorStatus = true;
		});
	}

	cargarUsuario(){
		this.auth.getUserData()
		.subscribe( (data:any) => {
			//console.log(data);
			this.username = data.nombre;
			this.email = data.email;
			this.userId = data.id;
			this.permisos = data.permisos;
			
			this.user = new User(this.userId, this.username, this.email, null, this.permisos);
		}, error => {
			console.log(error);
		});
		
	}

	cargarUsuarios(){
		this.secgralService.getUsers()
		.subscribe( (data:any) => {
			this.users = data.users;
		}, error => {
			console.log(error);
		});
	}

	cargarAreas(){
		this.secgralService.getAreas()
		.subscribe( (data:any) => {
			this.areas = data.areas;
		}, error => {
			console.log(error);
		});
	}

	cargarCategorias(){
		this.secgralService.getCategorias()
		.subscribe( (data:any) => {
			this.categorias = data.categorias;
		}, error => {
			console.log(error);
		});
	}

	seleccionarUsuario(user = null){
		if(user){
			this.nuevoUser = false;
			this.userSeleccionado = user;
			this.userAEditar = new User(
				null,
				this.userSeleccionado.nombre,
				this.userSeleccionado.email,
				null,
				this.userSeleccionado.permisos
			);
		}
		else{
			this.nuevoUser = true;
			this.userAEditar = new User(null, null, null, null, null);
		}
	}

	guardarUsuario(){
		if(!this.nuevoUser){
			this.editarUsuario();
		}
		else{
			this.nuevoUsuario();
		}
	}

	nuevoUsuario(){
		this.sending = true;
		this.errorStatus = false;

		this.secgralService.newUser(this.userAEditar)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarUsuario();
			this.cargarUsuarios();

			setTimeout(() => {
				(<any>$('#editarUsuarioModal')).modal('hide');
				this.okStatus = false;
				this.nueva = false;
			}, 2000);
		}, error => {
			console.log(error);
			this.sending = false;
			this.errorStatus = true;
		});
	}

	editarUsuario(){
		let id = this.userSeleccionado.id ? this.userSeleccionado.id : this.userSeleccionado.sub;

		this.sending = true;
		this.errorStatus = false;
		this.secgralService.editUser(id, this.userAEditar)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarUsuario();
			this.cargarUsuarios();

			setTimeout(() => {
				(<any>$('#editarUsuarioModal')).modal('hide');
				this.okStatus = false;
				this.nueva = false;
			}, 2000);
		}, error => {
			console.log(error);
			this.sending = false;
			this.errorStatus = true;
		});
	}

	inactivarUser(user){
		this.sending = true;
		this.errorStatus = false;

		this.userSeleccionado = user;
		this.secgralService.desactivarUser(this.userSeleccionado.id)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarUsuario();
			this.cargarUsuarios();

			setTimeout(() => {
				(<any>$('#eliminarUsuarioModal')).modal('hide');
				this.okStatus = false;
			}, 2000);
		}, error => {
			console.log(error);

			this.sending = false;
			this.errorStatus = true;
		})
	}

	activarUser(user){
		this.sending = true;
		this.errorStatus = false;

		this.userSeleccionado = user;
		this.secgralService.activarUser(this.userSeleccionado.id)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarUsuario();
			this.cargarUsuarios();

			setTimeout(() => {
				(<any>$('#eliminarUsuarioModal')).modal('hide');
				this.okStatus = false;
			}, 2000);
		}, error => {
			console.log(error);

			this.sending = false;
			this.errorStatus = true;
		})
	}

	cambiarPassword(user){
		
		this.sending = true;
		this.errorStatus = false;

		let id = user.sub ? user.sub : user.id;
		let pass = this.newPass;
		this.newPass = null;

		this.secgralService.changePassword(id, {password: pass})
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			this.cargarUsuario();
			this.cargarUsuarios();

			setTimeout(() => {
				(<any>$('#cambiarPasswordModal')).modal('hide');
				this.okStatus = false;
			}, 2000);
		}, error => {
			console.log(error);

			this.sending = false;
			this.errorStatus = true;
		})
	}
}
