import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { Pase } from '../../models/pase.model';

@Component({
  selector: 'app-editar-pases',
  templateUrl: './editar-pases.component.html',
  styleUrls: ['./editar-pases.component.css']
})
export class EditarPasesComponent implements OnInit {

	id: string;
	fecha: Date;
  	numero:any;
	pase: Pase = null;
	copiaPase: Pase;
	areas: any;
	areaAgregar: string = null;
	contenidosPasesArray = [];

	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;

	sendingArea: boolean = false;
	okStatusArea: boolean = false;
	errorStatusArea: boolean = false;

	nivelPermisos: any = localStorage.getItem('access');

  constructor(private location: Location,
			  private route: ActivatedRoute,
			  private router: Router,
			  private secgralService: SecgralService) {

	this.sending = false;
	this.okStatus = false;
	this.errorStatus = false;

	this.fecha = new Date();
	this.numero = null;
	this.copiaPase = null;
	
	this.route.paramMap.subscribe(params => {
		this.id = params.get("id");
		
		this.secgralService.getPase(this.id)
		.subscribe( (data:any) => {
			
			this.pase = new Pase(null, this.numero, 3, '', this.fecha, null, null, 0, null, null, '', null, null);
			this.pase.id = data.pase.id;
			this.pase.numero = data.pase.numero;
			this.pase.tipo = data.pase.tipo.id;
			this.pase.num_expte = data.pase.num_expte;
			this.pase.fecha = data.pase.fecha;
			this.pase.referente = data.pase.referente;
			this.pase.destinatario = data.pase.destinatario;
			this.pase.area_id = data.pase.area_id;
			this.pase.user_id = data.pase.user_id;
			this.pase.expediente_id = data.pase.expediente_id;
			this.pase.contenido = data.pase.contenido;
			this.contenidosPasesArray = data.pase.contenido_pases;
			console.log(this.pase);
		}, error => {
			console.log(error);
		});
	});

	this.getAreas();
  }

  ngOnInit() {
  }

	getAreas(){
		this.secgralService.getAreas()
		.subscribe((data: any) => {
			this.areas = data.areas;
		}, error => {
			console.log(error);
		});
	}

  editarPase(id){
	  console.log(this.contenidosPasesArray);
	  
  	this.sending = true;
	this.okStatus = false;
	this.errorStatus = false;

  	this.copiaPase = this.pase;
  	//this.copiaPase.tipo = this.copiaPase.tipo.id;
	this.secgralService.editPase(id, this.copiaPase)
	  .subscribe( (data:any) => {
		this.sending = false;
		this.okStatus = true;

		for(let cont of this.contenidosPasesArray){
			this.editarContenido(cont.id, cont);
		}

		console.log(data);
		setTimeout(() => {
			this.router.navigate(['/pases/ver/', id]);
		}, 3000); 
	  },
	  error => {
		this.sending = false;
		this.errorStatus = true;

		console.log(error);
	  });
  }

  eliminarPase(id){
  	this.sending = true;
	this.okStatus = false;
	this.errorStatus = false;

	this.secgralService.delPase(id)
	  .subscribe( (data:any) => {
	  	this.sending = false;
		this.okStatus = true;

		for(let cont of this.contenidosPasesArray){
			this.eliminarContenido(cont.id);
		}

		console.log(data);
		(<any>$('#deleteModal')).modal('hide');

		setTimeout(() => {
			this.router.navigate(['/pases']);
		}, 3000);
	  },
	  error => {
	  	this.sending = false;
		this.errorStatus = true;

		console.log(error);
	  });
  }
	  
	editarContenido(id, contenido){
		delete contenido.destino;
		delete contenido.updated_at;
		this.secgralService.editContenidoPase(id, contenido)
		.subscribe((data:any) => {

		}, error => {
			console.log("Error al editar contenido: ", error);
		})
	}

	eliminarContenido(id){
		this.secgralService.delContenidoPase(id)
		.subscribe((data:any) => {
		
		}, error => {
			console.log("Error al eliminar contenido: ", error);
		})
	}

	nuevaArea() {
		this.sendingArea = true;
		this.errorStatusArea = false;
		this.secgralService.newArea({ area: this.areaAgregar })
			.subscribe((data: any) => {
				this.sendingArea = false;
				this.okStatusArea = true;

				this.areaAgregar = null;
				this.getAreas();
				setTimeout(() => {
					(<any>$('#areasModal')).modal('hide');
					this.okStatusArea = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sendingArea = false;
				this.errorStatusArea = true;
			});
	}

  atras(){
	this.location.back();
  }

  agregarDestino(){
	this.contenidosPasesArray.push({ orden: 0, pase_id: null, area_id: null, destinatario: '', contenido: '' });
}

eliminarDestino(i){
	this.contenidosPasesArray.splice(i, 1);
}

}
