export class NormaLegal {
	constructor(
		public id:number,
		public tipo: any,
		public numero: number,
		public fecha: Date,
		public num_expte: string,
		public user_id: any,
		public modelo_id: number,
		public reservado:boolean,
		public descripcion:string,
		public visto: string,
		public considerando: string,
		public articulado: string,
		public created_at: any,
		public updated_at: any
	){}
}
