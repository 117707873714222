import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Expediente } from '../../models/expediente.model';
import { Pase } from '../../models/pase.model';
import { SecgralService } from '../../services/secgral.service';


@Component({
	selector: 'app-pase-expediente',
	templateUrl: './pase-expediente.component.html',
	styleUrls: ['./pase-expediente.component.css']
})
export class PaseExpedienteComponent implements OnInit {

	expedienteId: string = null;
	expedienteAPasar: Expediente;
	numero: any;
	fecha: Date;
	fechaString: any;
	pase: Pase;
	areas: any;
	areaAgregar: string = null;
	contenidosPasesArray = [{orden: 0, pase_id: null, area_id: null, destinatario: '', contenido: ''}];

	movimientoOption: boolean;
	paseOption:boolean;
	modelosPases:any;
	modeloSeleccionado: any;
	idMovimiento:any;
	loading: boolean;

	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;

	sendingArea: boolean = false;
	okStatusArea: boolean = false;
	errorStatusArea: boolean = false;

	constructor(private location: Location,
				private router: Router,
				private route: ActivatedRoute,
				private secgralService: SecgralService) {

	this.sending = false;
	this.okStatus = false;
	this.errorStatus = false;

	this.loading = true;
	this.expedienteAPasar = null;
	this.numero = null;
		this.fecha = new Date();

	if (this.fecha.getMonth() >= 9) {
		this.fechaString = this.fecha.getFullYear() + '-' + (this.fecha.getMonth() + 1) + '-' + this.fecha.getDate();
	}else{
		this.fechaString = this.fecha.getFullYear() + '-0' + (this.fecha.getMonth() + 1) + '-' + this.fecha.getDate();
	}
	
	this.expedienteAPasar = new Expediente(null, this.fecha.toISOString().split('T')[0], null, localStorage.getItem('sub'), '', '', '', '');
	this.pase = new Pase(null, this.numero, null, '', this.fecha.toISOString().split('T')[0], null, null, null, null, null, '', null, null);

	this.movimientoOption = true;
	this.paseOption = false;
	this.modeloSeleccionado = 0;
	this.idMovimiento = null;

	this.route.paramMap.subscribe(params => {
		this.expedienteId = params.get("id") == "null" ? null : params.get("id");

		if (this.expedienteId && this.expedienteId != "nuevo") {
			this.secgralService.getExpediente(this.expedienteId)
			.subscribe( (data:any) => {
				//console.log(data);
				this.expedienteAPasar = data.expediente;
				this.pase.num_expte = this.expedienteAPasar.num_expte;
				this.pase.referente = this.expedienteAPasar.extracto;
				this.loading = false;
			},error => {
				console.log(error);
			});
		}
		else if(this.expedienteId && this.expedienteId == "nuevo"){
			this.paseOption = false;
			this.loading = false;
		}
		else{
			this.paseOption = true;
			this.loading = false;
		}
	});

	this.getAreas();


	this.secgralService.getModelosPases()
	.subscribe( (data:any) => {
		this.modelosPases = data.modelosPases;
	}, error => {
		console.log(error);
	});

	}

	ngOnInit() {
	}

	getAreas() {
		this.secgralService.getAreas()
		.subscribe((data: any) => {
			this.areas = data.areas;
		}, error => {
			console.log(error);
		});
	}

	pasar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.expedienteAPasar.id = null;
		this.expedienteAPasar.fecha = this.pase.fecha;
		this.expedienteAPasar.area_salida = this.pase.area_id;
		this.expedienteAPasar.created_at = null;
		this.expedienteAPasar.updated_at = null;
		this.pase.num_expte = this.expedienteAPasar.num_expte;
		if (this.expedienteAPasar.num_expte && this.movimientoOption) {
			this.secgralService.inExpediente(this.expedienteAPasar)
				.subscribe( (response:any) => {

					//console.log(response);
					this.idMovimiento = response.expediente.id;
					this.pase.expediente_id = this.idMovimiento;
					if (this.paseOption && (this.pase.tipo == 3 || this.pase.tipo == 4)) {
						this.nuevoInforme();
					}
					else if (this.paseOption && this.pase.tipo == 5) {
						this.nuevoMemo();
					}
					else if (this.paseOption && (this.pase.tipo == 6 || this.pase.tipo == 7)) {
						this.nuevaNota();
					}
					else if (!this.paseOption) {
						this.sending = false;
						this.okStatus = true;
						this.redireccionar();
					}
				}, error => {
					this.sending = false;
					this.errorStatus = true;

					console.log(error);
				});
		}
		else{
			if (this.paseOption && (this.pase.tipo == 3 || this.pase.tipo == 4)) {
				this.nuevoInforme();
			}
			else if (this.paseOption && this.pase.tipo == 5) {
				this.nuevoMemo();
			}
			else if (this.paseOption && (this.pase.tipo == 6 || this.pase.tipo == 7)) {
				this.nuevaNota();
			}
		}
	
	}

	enviarContenido(contenido){
		this.secgralService.newContenidoPase(contenido)
		.subscribe((data:any) => {

		}, error => {
			console.log("Error al crear contenido: ", error);
		})
	}
	
	nuevoInforme(){
		this.secgralService.getNumeroPaseDisponible(this.fecha.getFullYear())
		.subscribe( (data:any) => {
			this.pase.numero = data.ultima != null ? (data.ultima.numero + 1) : 1;
			
			this.secgralService.newPase(this.pase)
			.subscribe( (data:any) => {
				let i = 0;
				for(let contenido of this.contenidosPasesArray){
					contenido.pase_id = data.pase.id;
					contenido.orden = i;
					i++;
					this.enviarContenido(contenido);	
				}
				
				this.sending = false;
				this.okStatus = true;

				this.redireccionar(data.pase.id);
			},error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			});
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
	}

	nuevoMemo(){
		this.secgralService.getNumeroMemoDisponible(this.fecha.getFullYear())
		.subscribe( (data:any) => {
			this.pase.numero = data.ultima != null ? (data.ultima.numero + 1) : 1;
			
			this.secgralService.newPase(this.pase)
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;
				this.redireccionar(data.pase.id);

			},error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			});
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
	}

	nuevaNota(){
		this.pase.numero = null;
		
		this.secgralService.newPase(this.pase)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;
			this.redireccionar(data.pase.id);
		},error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
		
	}

	checkMovimientoOption(e){
		if (e.target.checked) {
			this.movimientoOption = true;
		}else{
			this.movimientoOption = false;
		}
	}

	checkInformeOption(e){
		if (e.target.checked) {
			this.paseOption = true;
		}else{
			this.paseOption = false;
		}
	}

	modelosOption(e, index){
		if(e.target.value){
			this.contenidosPasesArray[index].area_id = this.modelosPases[e.target.value].area_id;
			this.contenidosPasesArray[index].destinatario = this.modelosPases[e.target.value].destinatario;
			this.contenidosPasesArray[index].contenido = this.modelosPases[e.target.value].contenido;
		}

		if(index == 0){
			this.pase.area_id = this.contenidosPasesArray[index].area_id
		}
	}

	atras(){
		this.location.back();
	}

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
		.then(() => {
			this.router.navigated = false;
			this.router.navigate([this.router.url]);
		});
	}

	redireccionar(id = null){
		setTimeout(() => {
			if (this.paseOption) {
				this.router.navigate(['/pases/ver', id]);
			}
			else{
				this.router.navigate(['/expedientes']); 
			}
		}, 3000); 
	}

	nuevaArea() {
		this.sendingArea = true;
		this.errorStatusArea = false;
		this.secgralService.newArea({ area: this.areaAgregar })
			.subscribe((data: any) => {
				this.sendingArea = false;
				this.okStatusArea = true;

				this.areaAgregar = null;
				this.getAreas();
				setTimeout(() => {
					(<any>$('#areasModal')).modal('hide');
					this.okStatusArea = false;
				}, 2000);
			}, error => {
				console.log(error);
				this.sendingArea = false;
				this.errorStatusArea = true;
			});
	}

	agregarDestino(){
		this.contenidosPasesArray.push({ orden: 0, pase_id: null, area_id: null, destinatario: '', contenido: '' });
	}

	eliminarDestino(i){
		this.contenidosPasesArray.splice(i, 1);
	}
}
