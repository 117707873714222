import { Pipe, PipeTransform } from '@angular/core';
import { Modelo } from '../models/modelo.model';

@Pipe({
  name: 'modelosFilter'
})
export class ModelosFilterPipe implements PipeTransform {

  transform(modelo: Modelo[], searchTerm: string): any {
		if (!modelo || !searchTerm) {
			return modelo;
		}
		
		return modelo.filter( normaLegal =>{
			if (normaLegal.titulo.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
				return true;
			}
			else if(!normaLegal.descripcion){return false;}
			else{
				return normaLegal.descripcion.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
			}
		}); 
	}
  }


