import { Pipe, PipeTransform } from '@angular/core';
import { Expediente } from '../models/expediente.model';

@Pipe({
  name: 'expedientesFilter'
})

export class ExpedientesFilterPipe implements PipeTransform {

	transform(expedientes: Expediente[], searchTerm: string): Expediente[] {
		if (!expedientes || !searchTerm) {
			return expedientes;
		}
		return expedientes.filter( expediente =>{
			if (expediente.num_expte.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
				return true;
			}else{
				return expediente.extracto.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
			}
		}); 	
	}
}

