import { Component, OnInit } from '@angular/core';
import { slideInAnimation } from '../route-animation';

@Component({
	selector: 'app-main-content',
	templateUrl: './main-content.component.html',
	styleUrls: ['./main-content.component.css'],
	animations: [slideInAnimation]
})
export class MainContentComponent implements OnInit {

	constructor() { }
	
	ngOnInit() {
	}

}
