import { Pipe, PipeTransform } from '@angular/core';
import { Pase } from '../models/pase.model';

@Pipe({
  name: 'informesFilter'
})
export class InformesFilterPipe implements PipeTransform {

	transform(pases: Pase[], searchTerm: string): Pase[] {
		if (!pases || !searchTerm) {
			return pases;
		}
		return pases.filter( expediente =>{
			return expediente.num_expte.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
		}); 	
	}
}
