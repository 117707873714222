import { Pipe, PipeTransform } from '@angular/core';
import { Expediente } from '../models/expediente.model';

@Pipe({
  name: 'entradasSalidas'
})
export class EntradasSalidasPipe implements PipeTransform {

  transform(expedientes: Expediente[], movimiento: string): Expediente[] {

		if (!expedientes || !movimiento) {
			return expedientes;
		}

		return expedientes.filter(expediente => {
			if (movimiento == 'entrada'){
				return !expediente.area_salida;
			}else{
				return expediente.area_salida;
			}
		});
	}

}
