import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators'
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	//apiUrl: string = 'http://localhost/secgralapi/public/api/';
	apiUrl: string = 'https://api.secgralmsp.com/public/api/';
	userToken: string = null;

	constructor(private http: HttpClient,
				private router: Router) {
		this.readToken();
	}

	logout(){
		localStorage.removeItem('token');
		localStorage.removeItem('username');
		localStorage.removeItem('access');
		localStorage.removeItem('sub');

		this.router.navigateByUrl('login');
	}

	login(user){
		let json = JSON.stringify(user);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(this.apiUrl + 'login', params, {headers: headers})
		.pipe(
			map( (resp:any) => {
				this.saveToken(resp);
				//console.log(user);
				return resp;
			})
		);
	}

	getUserData(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.get(this.apiUrl + 'getuser', {headers: headers})
		.pipe(
			map( (resp:any) => {
				localStorage.setItem('user', resp.email);
				localStorage.setItem('sub', resp.id);
				localStorage.setItem('username', resp.nombre);
				localStorage.setItem('access', resp.permisos);
				return resp;
			})
		);

	}

	private saveToken(token:string){
		this.userToken = token;
		localStorage.setItem('token', token);
	}

	readToken(){
		if(localStorage.getItem('token')){
			this.userToken = localStorage.getItem('token');
		}
		else{
			this.userToken = '';
		}

		return this.userToken;
	}

	isLoggedIn(){
		if(localStorage.getItem('token')){
			return true;
		}
		return false;
	}

	isActive(){
		let headers = new HttpHeaders().set('Authorization', localStorage.getItem('token')).set('Content-Type', 'application/x-www-form-urlencoded');
		this.http.get(this.apiUrl + 'checkactive', {headers: headers})
		.subscribe(
			(resp:any) => {}, err => {
				console.log(err);
				if(err.status == 403){
					this.logout();
					console.error("LA SESION HA EXPIRADO O EL USUARIO HA SIDO DESACTIVADO");
				}
			}
		);
	}
}
