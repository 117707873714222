import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPaginationModule } from 'ngx-pagination';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MainContentComponent } from './main-content/main-content.component';
import { HomeComponent } from './components/home/home.component';
import { ExpedientesComponent } from './components/expedientes/expedientes.component';
import { PasesComponent } from './components/pases/pases.component';
import { NormasComponent } from './components/normas/normas.component';
import { ModelosComponent } from './components/modelos/modelos.component';
import { TablaNormasComponent } from './components/tabla-normas/tabla-normas.component';
import { TablaExpedientesComponent } from './components/tabla-expedientes/tabla-expedientes.component';
import { DetalleExpedientesComponent } from './components/detalle-expedientes/detalle-expedientes.component';
import { DetalleNormasComponent } from './components/detalle-normas/detalle-normas.component';
import { TablaModelosComponent } from './components/tabla-modelos/tabla-modelos.component';
import { NewNormaComponent } from './components/new-norma/new-norma.component';
import { ModalExpedientesComponent } from './components/modal-expedientes/modal-expedientes.component';
import { EditarExpedientesComponent } from './components/editar-expedientes/editar-expedientes.component';
import { PaseExpedienteComponent } from './components/pase-expediente/pase-expediente.component';
import { TablaPasesComponent } from './components/tabla-pases/tabla-pases.component';
import { DetallePasesComponent } from './components/detalle-pases/detalle-pases.component';
import { EditarPasesComponent } from './components/editar-pases/editar-pases.component';
import { EditarNormaComponent } from './components/editar-norma/editar-norma.component';
import { NewModeloComponent } from './components/new-modelo/new-modelo.component';
import { EditarModeloComponent } from './components/editar-modelo/editar-modelo.component';
import { ConfigComponent } from './components/config/config.component';
import { NewModeloPaseComponent } from './components/new-modelo-pase/new-modelo-pase.component';
import { NewNormaMultipleComponent } from './components/new-norma-multiple/new-norma-multiple.component';
import { BusquedaAvanzadaComponent } from './components/busqueda-avanzada/busqueda-avanzada.component';
import { NormaExpedienteFilterPipe } from './pipes/norma-expediente-filter.pipe';
import { NormaModeloFilterPipe } from './pipes/norma-modelo-filter.pipe';
import { NormaContenidoFilterPipe } from './pipes/norma-contenido-filter.pipe';
import { InformesFilterPipe } from './pipes/informes-filter.pipe';
import { ExpedientesFilterPipe } from './pipes/expedientes-filter.pipe';
import { EntradasSalidasPipe } from './pipes/entradas-salidas.pipe';
import { NormaLegalFilterPipe } from './pipes/norma-legal-filter.pipe';
import { NumeradosFilterPipe } from './pipes/numerados-filter.pipe';
import { TipoNormalLegalPipe } from './pipes/tipo-normal-legal.pipe';
import { ModelosFilterPipe } from './pipes/modelos-filter.pipe';
import { PasesFilterPipe } from './pipes/pases-filter.pipe';
import { NumeroNormaFilterPipe } from './pipes/numero-norma-filter.pipe';
import { ReciboExpedientesComponent } from './components/recibo-expedientes/recibo-expedientes.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NormasComponent,
    HomeComponent,
    ExpedientesComponent,
    TablaNormasComponent,
    TablaExpedientesComponent,
    DetalleExpedientesComponent,
    DetalleNormasComponent,
    ModelosComponent,
    TablaModelosComponent,
    NewNormaComponent,
    ModalExpedientesComponent,
    EditarExpedientesComponent,
    PaseExpedienteComponent,
    PasesComponent,
    TablaPasesComponent,
    DetallePasesComponent,
    EditarPasesComponent,
    EditarNormaComponent,
    NewModeloComponent,
    EditarModeloComponent,
    ConfigComponent,
    NewModeloPaseComponent,
    NewNormaMultipleComponent,
    BusquedaAvanzadaComponent,
    LoginComponent,
    MainContentComponent,
    NormaExpedienteFilterPipe,
    NormaModeloFilterPipe,
    NormaContenidoFilterPipe,
    NormaLegalFilterPipe,
    NumeradosFilterPipe,
    TipoNormalLegalPipe,
    InformesFilterPipe,
    ExpedientesFilterPipe,
    EntradasSalidasPipe,
    ModelosFilterPipe,
    PasesFilterPipe,
    NumeroNormaFilterPipe,
    ReciboExpedientesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    DragDropModule,
    ScrollingModule,
    RouterModule.forRoot(ROUTES, {useHash: true})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
