import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { NormaLegal } from '../../models/norma-legal.model';

const defaultQueryParams: any = {
	page: 1,
	tipo: null,
	numero: null,
	expediente: null,
	descripcion: null,
	contenido: null,
	reservado: null,
	numerado: null,
	full: null,
}

@Component({
	selector: 'app-tabla-normas',
	templateUrl: './tabla-normas.component.html',
	styleUrls: ['./tabla-normas.component.css']
})
export class TablaNormasComponent implements OnInit {

	normas = [];
	normaLegal: any;
	pageActual: number = 1;
	numRes: number;
	fecha: Date;
	num_expte: string;
	descripcion: string;
	tipo: any;
	numeroDisponible: boolean;
	loading: boolean;
	loadingGetNorma: boolean;
	validandoNumRes: boolean;

	queryParams: any;
	tipoSelected: number;
	numeradoSelected: string;
	reservadoSelected: string;
	numeroSearch: number;
	expedienteSearch: string;
	descripcionSearch: string;


	searchTerm: string;
	searchTermNumero: number;
	numeradoFilter: string;
	tipoNormalLegalFilter: string;

	numerando: boolean;
	numerarStatus: boolean;
	errorStatus: boolean;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private secgralService: SecgralService,
		private route: ActivatedRoute,
		private router: Router) {

		this.queryParams = { ...defaultQueryParams };
		this.tipoSelected = null;
		this.numeradoSelected = null;
		this.reservadoSelected = null;
		this.numeroSearch = null;
		this.expedienteSearch = null;
		this.descripcionSearch = null;

		this.numeradoFilter = "todo";
		this.tipoNormalLegalFilter = null;
		

		this.normaLegal = null;
		this.numRes = null;
		this.fecha = new Date();
		this.num_expte = null;
		this.descripcion = null;
		this.numeroDisponible = true;
		this.tipo = null;
		this.loading = true;
		this.loadingGetNorma = true;
		this.validandoNumRes = false;

		this.numerando = false;
		this.numerarStatus = false;
		this.errorStatus = false;

		this.normaLegal = new NormaLegal(null,
			this.tipo,
			this.numRes,
			this.fecha,
			this.num_expte,
			2,
			null,
			true,
			this.descripcion,
			null,
			null,
			null,
			null,
			null
		);

		this.route.paramMap.subscribe(params => {
			this.tipoNormalLegalFilter = params.get("tipo");

			if (this.tipoNormalLegalFilter == 'resolucion') {
				this.secgralService.getResoluciones()
					.subscribe((data: any) => {
						this.normas = data.resoluciones;
						this.loading = false;
					});
			}
			else if (this.tipoNormalLegalFilter == 'decreto') {
				this.secgralService.getDecretos()
					.subscribe((data: any) => {
						this.normas = data.decretos;
						this.loading = false;
					});
			}
			else {
				this.secgralService.getNormas()
					.subscribe((data: any) => {
						this.normas = data.normalegal;
						this.loading = false;
					});
			}
		});


		this.secgralService.getNumeroResDisponible(this.fecha.getFullYear())
			.subscribe((data: any) => {
				if (data.ultima) {
					this.normaLegal.numero = data.ultima.numero + 1;
				} else {
					this.normaLegal.numero = 1;
				}
			}, error => {
				console.log(error);
			});


	}

	ngOnInit() {
		console.log(this.queryParams);
	}

	getNorma(id) {

		this.loadingGetNorma = true;
		this.secgralService.getNorma(id)
			.subscribe((data: any) => {
				this.normaLegal = data.normaLegal;
				this.normaLegal.fecha = this.fecha;

				delete this.normaLegal.user;
				delete this.normaLegal.modelo;
				this.loadingGetNorma = false;

				if (this.normaLegal.tipo.id == 1) {
					this.numeroDisponible = true;
					this.actualizarNumDisponible(this.normaLegal.fecha.getFullYear());
				}
			}, error => {
				console.log(error);
				this.loadingGetNorma = false;
			});
	}

	checkNumDisponible(e) {
		if (e.target.value) {
			let newYear = (new Date(e.target.value)).getFullYear();

			if (newYear != this.fecha.getFullYear()) {
				this.actualizarNumDisponible(newYear);
			}
			//this.fecha = new Date(this.normaLegal.fecha);
		}
	}

	checkNumeroDisponible() {
		this.validandoNumRes = true;
		if (this.normaLegal.numero) {
			let tipo = this.normaLegal.tipo.id == 1 ? 'resoluciones' : 'decretos';
			this.secgralService.checkNumDisponible(tipo, this.normaLegal.numero, this.fecha.getFullYear())
				.subscribe((data: any) => {
					if (data.result.length == 0) {
						this.numeroDisponible = true;
					} else {
						this.numeroDisponible = false;
					}
					this.validandoNumRes = false;
				}, error => {
					console.log(error);
				});
		}
	}

	actualizarNumDisponible(year) {
		this.secgralService.getNumeroResDisponible(year)
			.subscribe((data: any) => {
				if (data.ultima) {
					this.normaLegal.numero = data.ultima.numero + 1;
				}
				else {
					this.normaLegal.numero = 1;
				}
			}, error => {
				console.log(error);
			});
	}

	numerarNorma(id) {
		this.numerando = true;
		this.errorStatus = false;

		let normaLegalNumerar = new NormaLegal(this.normaLegal.id,
			this.normaLegal.tipo.id,
			this.normaLegal.numero,
			this.normaLegal.fecha,
			this.normaLegal.num_expte,
			this.normaLegal.user_id,
			this.normaLegal.modelo_id,
			this.normaLegal.reservado,
			this.normaLegal.descripcion,
			this.normaLegal.visto,
			this.normaLegal.considerando,
			this.normaLegal.articulado,
			this.normaLegal.created_at,
			this.normaLegal.updated_at
		);

		this.secgralService.editNorma(id, normaLegalNumerar)
			.subscribe((data: any) => {
				this.numerando = false;
				this.numerarStatus = true;
				setTimeout(() => {
					(<any>$('#numerarModal')).modal('hide');
					this.recargarTabla();
					this.numerarStatus = false;
				}, 2000);
			}, error => {
				this.numerando = false;
				this.errorStatus = true;
				this.checkNumeroDisponible();
				console.log(error);
			});
	}

	borrarNumero(id) {
		this.secgralService.getNorma(id)
			.subscribe((data: any) => {
				this.normaLegal = data.normaLegal;
				delete this.normaLegal.user;
				delete this.normaLegal.modelo;
				this.normaLegal.tipo = this.normaLegal.tipo.id;
				this.normaLegal.fecha = null;
				this.normaLegal.numero = null;

				this.secgralService.editNorma(id, this.normaLegal)
					.subscribe((data: any) => {
						setTimeout(() => {
							this.recargarTabla();
						}, 100);
					}, error => {
						console.log(error);
					});

			}, error => {
				console.log(error);
			});
	}

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
			.then(() => {
				this.router.navigated = false;
				this.router.navigate([this.router.url]);
			});
	}

	recargarTabla() {
		this.loading = true;
		this.normas = [];
		this.secgralService.getNormas()
			.subscribe((data: any) => {
				this.normas = data.normalegal;
				this.loading = false;
			});
	}

	cambiarFecha(event) {
		this.normaLegal.fecha = event;
		this.fecha = new Date(event);
		this.fecha.setHours(this.fecha.getHours() + 3);
	}

	cleanParams() {
		let cleanParams = {};
		for (var propName in this.queryParams) {
			if (this.queryParams[propName] && this.queryParams[propName] !== null && this.queryParams[propName] !== "") {
				cleanParams[propName] = this.queryParams[propName];
			}
		}
		return cleanParams;
	}

	buscarNormas(resetPaging = true) {
		this.loading = true;
		this.normas = [];
		if (resetPaging) {
			this.queryParams.page = 1;
		}
		this.secgralService.normasSearch(this.cleanParams())
			.subscribe((data: any) => {
				this.normas = data.normalegal;
				this.loading = false;
			});
	}

	resetSearchParams() {
		this.tipoSelected = null;
		this.numeradoSelected = null;
		this.reservadoSelected = null;
		this.numeroSearch = null;
		this.expedienteSearch = null;
		this.descripcionSearch = null;
		this.queryParams = { ...defaultQueryParams };
		this.buscarNormas();
	}

	buscarNormasPrimeraPagina() {
		if (this.queryParams.page > 1) {
			this.queryParams.page = 1;
			this.buscarNormas();
		}
	}

	buscarNormasAnteriorPagina() {
		if (this.queryParams.page > 1) {
			this.queryParams.page = this.queryParams.page - 1;
			this.buscarNormas(false);
		}
	}

	buscarNormasProximaPagina() {
		this.queryParams.page = this.queryParams.page + 1;
		this.buscarNormas(false);
	}

	handletipoSelectedChange(tipo) {
		this.tipoSelected = tipo;
		this.queryParams.tipo = tipo;
		this.buscarNormas();
	}

	handleNumeradoSelectedChange(numerado) {
		this.numeradoSelected = numerado;
		this.queryParams.numerado = numerado;
		this.buscarNormas();
	}

	handleReservadoSelectedChange(reservado) {
		this.reservadoSelected = reservado;
		this.queryParams.reservado = reservado;
		this.buscarNormas();
	}

	handleSearchButton() {
		this.queryParams.numero = this.numeroSearch;
		this.queryParams.expediente = this.expedienteSearch;
		this.queryParams.descripcion = this.descripcionSearch;
		this.buscarNormas();
	}
}
