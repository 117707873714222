import { Pipe, PipeTransform } from '@angular/core';
import { NormaLegal } from '../models/norma-legal.model';

@Pipe({
  name: 'numeradosFilter'
})
export class NumeradosFilterPipe implements PipeTransform {

  transform(normaLegal: NormaLegal[], numeradoFilter: string): NormaLegal[] {

		if (!normaLegal || !numeradoFilter || numeradoFilter == 'todo') {
			return normaLegal;
		}

		else if (numeradoFilter == 'sinnumerar') {
			return normaLegal.filter( normaLegal =>
			!normaLegal.numero);
		}

		else if (numeradoFilter == 'reservados') {
			return normaLegal.filter( normaLegal =>
			normaLegal.reservado);
		}

		else{
			return normaLegal.filter( normaLegal =>
			normaLegal.numero);
		}
		
	}

}
