import { Pipe, PipeTransform } from '@angular/core';
import { NormaLegal } from '../models/norma-legal.model';

@Pipe({
	name: 'normaExpedienteFilter'
})
export class NormaExpedienteFilterPipe implements PipeTransform {

	transform(normasLegales: any[], searchTerm: string): NormaLegal[] {

		if (!normasLegales || !searchTerm) {
			return normasLegales;
		}
		return normasLegales.filter( normaLegal => {
			if (normaLegal.num_expte && normaLegal.num_expte.indexOf(searchTerm) !== -1) {
				return true;
			}
			else{
				return false;
			}
		}); 	
	}

}
