import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Expediente } from '../../models/expediente.model';
import { ModeloPase  } from '../../models/modelo-pase.model'
import { Pase } from '../../models/pase.model';
import { SecgralService } from '../../services/secgral.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-pases',
  templateUrl: './pases.component.html',
  styleUrls: ['./pases.component.css']
})
export class PasesComponent implements OnInit {

	expedienteAPasar: Expediente;
	pase: Pase;
	modeloPase: ModeloPase;
	numero:any;
	fecha: Date;
	areas: any;
	paseOption:boolean;
	modelosPases:any;
	modeloSeleccionado: any;
	idMovimiento:any;

	nivelPermisos: any = localStorage.getItem('access');

  constructor(private router: Router,
			  private secgralService: SecgralService,
			  private auth: AuthService) {
	
	this.auth.isActive();
	this.fecha = new Date();
  	this.numero = null;

	this.expedienteAPasar = new Expediente(null, this.fecha, null, 2, '', '', null, null);
	this.pase = new Pase(null, this.numero, false, '', this.fecha, null, null, 0, null, null, '', null, null);
	this.modeloPase = new ModeloPase(null, null, null, null, null, 2, null, null, null, null);
	this.paseOption = false;

	this.modeloSeleccionado = 0;
	this.idMovimiento = null;

	this.secgralService.getAreas()
	  .subscribe( (data:any) => {
		this.areas = data.areas;
	  }, error => {
		console.log(error);
	  });

	this.secgralService.getModelosPases()
	  .subscribe( (data:any) => {
		this.modelosPases = data.modelosPases;
	  }, error => {
		console.log(error);
	  });

  }

  ngOnInit() {
  }

  pasar(){

	this.expedienteAPasar.id = null;
	this.expedienteAPasar.fecha = this.pase.fecha;
	this.expedienteAPasar.area_salida = this.pase.area_id;
	this.expedienteAPasar.num_expte = this.pase.num_expte;

	if (this.paseOption) {
		this.secgralService.inExpediente(this.expedienteAPasar)
		  .subscribe( (response:any) => {
			this.idMovimiento = response.expediente.id;
			this.pase.expediente_id = this.idMovimiento;
			if (this.paseOption) {
				this.secgralService.getNumeroPaseDisponible(this.fecha.getFullYear())
				.subscribe( (data:any) => {
				  this.pase.numero = data.ultima != null ? (data.ultima.numero + 1) : 1;
				  this.secgralService.newPase(this.pase)
					.subscribe( (data:any) => {
				  },error => {
					console.log(error);
				  });

				}, error => {
				  console.log(error);
				});

			}
		  },
		  error => {
			console.log(error);
		  });
	}else{
		this.secgralService.getNumeroPaseDisponible(this.fecha.getFullYear())
		.subscribe( (data:any) => {
			this.pase.numero = data.ultima != null ? (data.ultima.numero + 1) : 1;
			this.secgralService.newPase(this.pase)
			.subscribe( (data:any) => {
			},error => {
				console.log(error);
			});	
		},error => {
			console.log(error);
		});

	}
	setTimeout(() => {
	  (<any>$('#pasesModal')).modal('hide');
	  this.onRefresh(); 
	}, 500); 
  }

  nuevoModeloPase(){
	this.secgralService.newModeloPase(this.modeloPase)
	.subscribe( (data:any) => {
	  setTimeout(() => {
		(<any>$('#nuevoPaseModal')).modal('hide');
		this.onRefresh(); 
	  }, 500); 
	}, error => {
	  console.log(error);
	});
  }

  checkOption(e){
	if (e.target.checked) {
	  this.paseOption = true;
	}else{
	  this.paseOption = false;
	}
  }

  modelosOption(e){
	if(e.target.value){
	  this.pase.area_id = this.modelosPases[e.target.value].area_id;
	  this.pase.contenido = this.modelosPases[e.target.value].contenido;
	}
  }

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
		.then(() => {
			this.router.navigated = false;
			this.router.navigate([this.router.url]);
		});
	}

	cerrarModal(){
		(<any>$('#PaseModal')).modal('hide');
	}

}
