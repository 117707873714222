import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecgralService } from '../../services/secgral.service';
import { Modelo } from '../../models/modelo.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'app-editar-modelo',
	templateUrl: './editar-modelo.component.html',
	styleUrls: ['./editar-modelo.component.css']
})
export class EditarModeloComponent implements OnInit {

	modelo: Modelo = null;
	categorias:any;
	id: string;
	tipo: any;
	titulo: string;
	categoria: string;
	descripcion: string;
	campos: any;
	visto: string;
	considerando: string;
	articulado: string;
	
	sending: boolean;
	okStatus: boolean;
	errorStatus: boolean;

	copy: boolean = false;
	prevTitle: string = null;

	stage: number = 1;

	nivelPermisos: any = localStorage.getItem('access');

	constructor(private router: Router,
			private route: ActivatedRoute,
			private secgralService: SecgralService) {

		this.sending = false;
		this.okStatus = false;
		this.errorStatus = false;
		
		this.categorias = null;
		this.id = null;
		this.tipo = null;
		this.titulo = null;
		this.categoria = null;
		this.descripcion = null;
		this.campos = [];
		this.visto = null;
		this.considerando = null;
		this.articulado = null;

		this.route.paramMap.subscribe(params => {
			if (params.get("copia")){
				this.id = params.get("copia");
				this.copy = true;
			}
			else{
				this.id = params.get("id");
			}
		});



		this.secgralService.getModelo(this.id)
		.subscribe( (data:any) => {
			this.modelo = data.modelo;
			this.tipo = this.modelo.tipo.id;

			if(this.copy){
				this.prevTitle = this.modelo.titulo;
			}
			
			this.campos = JSON.parse(data.modelo.campos).campos;
		}, error => {
			console.log(error);
		});

		this.secgralService.getCategorias()
		.subscribe( (data:any) => {
			this.categorias = data.categorias;
		}, error => {
			console.log(error);
		})
	}

	ngOnInit() {
	}

	guardar(){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.modelo.campos = JSON.stringify({campos:this.campos});
		this.modelo.tipo = Number(this.tipo);
		//console.log(this.modelo);

		if(this.copy){
			this.secgralService.newModelo(this.modelo)
			.subscribe((data: any) => {
				this.sending = false;
				this.okStatus = true;

				setTimeout(() => {
					this.router.navigate(['/modelos']);
				}, 3000);
			}, error => {
				this.sending = false;
				this.errorStatus = true;

				console.log(error);
			});
		}
		else{
			this.secgralService.editModelo(this.id, this.modelo)
			.subscribe( (data:any) => {
				this.sending = false;
				this.okStatus = true;
	
				setTimeout(() => {
					this.router.navigate(['/modelos']);
				}, 3000); 
			}, error => {
				this.sending = false;
				this.errorStatus = true;
				console.log(error);
			});
		}
	}

	agregarCampo(){
		this.campos.push({titulo: '', selector: '', default: ''});
	}

	eliminarCampo(i){
		this.campos.splice(i, 1);
	}

	eliminarModelo(id){
		this.sending = true;
		this.okStatus = false;
		this.errorStatus = false;

		this.secgralService.delModelo(id)
		.subscribe( (data:any) => {
			this.sending = false;
			this.okStatus = true;

			(<any>$('#deleteModal')).modal('hide');
			
			setTimeout(() => {
				this.router.navigate(['/modelos']);
			}, 3000); 
		}, error => {
			this.sending = false;
			this.errorStatus = true;

			console.log(error);
		});
	}

	copyText(val) {
		$('.copyMsg').removeClass('hide');

		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		setTimeout(() => {
			$('.copyMsg').addClass('hide');
		}, 1200);
	}

	drop(event: CdkDragDrop<any>){
		moveItemInArray(this.campos, event.previousIndex, event.currentIndex);
		console.log(event.previousIndex, event.currentIndex);
			
	}

	moverCampo(prevIndex, newIndex){
		if (newIndex > -1 && newIndex < this.campos.length){
			let removedElement = this.campos.splice(prevIndex, 1);
			this.campos.splice(newIndex, 0, removedElement[0]);	
		}
	}

}
