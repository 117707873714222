import { Component, OnInit, Input } from '@angular/core';
import { SecgralService } from '../../services/secgral.service';


@Component({
  selector: 'app-tabla-modelos',
  templateUrl: './tabla-modelos.component.html',
  styleUrls: ['./tabla-modelos.component.css']
})
export class TablaModelosComponent implements OnInit {

	@Input() numExpediente: string;
  modelos = [];
  pageActual: number = 1;
  loading: boolean;
  searchTerm: string;

	nivelPermisos: any = localStorage.getItem('access');

  constructor(private secgralService: SecgralService) {
  	
  	this.searchTerm = null;
	this.loading = true;

	

	this.secgralService.getModelos()
	  .subscribe( (data:any) => {
		this.loading = false;
		for(let id in data.modelos){
			this.modelos.push(data.modelos[id]);
		  }
	  })
  }

  ngOnInit() {
  }

}
