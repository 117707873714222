import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { SecgralService } from '../../services/secgral.service';

@Component({
  selector: 'app-detalle-expedientes',
  templateUrl: './detalle-expedientes.component.html',
  styleUrls: ['./detalle-expedientes.component.css']
})
export class DetalleExpedientesComponent implements OnInit {

  id: string;
  expediente:any;
  pases:any;
  movimientos:any;
  normas:any;

  nivelPermisos: any = localStorage.getItem('access');

  constructor(private location: Location,
              private route: ActivatedRoute,
              private secgralService: SecgralService) {

    this.expediente = null;
    this.pases = [];
    this.movimientos = [];
    this.normas = [];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      
      this.expediente = null;
      this.pases = [];
      this.movimientos = [];
      this.normas = [];


      this.secgralService.getExpediente(this.id)
        .subscribe( (data:any) => {
          this.expediente = data.expediente;
          for(let id in data.expediente.pases){
            this.pases.push(data.expediente.pases[id]);
          }
          for(let id in data.movimientos){
            this.movimientos.push(data.movimientos[id]);
          }
          for(let id in data.normas){
            this.normas.push(data.normas[id]);
          }
      });
    });

  }

  atras(){
    this.location.back();
  }

}
