import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Expediente } from '../../models/expediente.model';
import { SecgralService } from '../../services/secgral.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-expedientes',
	templateUrl: './expedientes.component.html',
	styleUrls: ['./expedientes.component.css']
})
export class ExpedientesComponent implements OnInit {

		fecha: Date = new Date();
		fechaString: any;
		expediente: Expediente;
		sending: boolean = false;
		inStatus: boolean = false;
		errorStatus: boolean = false;
		errorMsg: string;
		listadoNumExp:any = [];

		nivelPermisos: any = localStorage.getItem('access');


	constructor(private router: Router,
				private secgralService: SecgralService,
				private auth: AuthService) {

		this.auth.isActive();

		if (this.fecha.getMonth() >= 9) {
			this.fechaString = this.fecha.getFullYear() + '-' + (this.fecha.getMonth() + 1) + '-' + this.fecha.getDate();
		}else{
			this.fechaString = this.fecha.getFullYear() + '-0' + (this.fecha.getMonth() + 1) + '-' + this.fecha.getDate();
		}

		this.expediente = new Expediente(null, this.fecha.toISOString().split('T')[0], null, localStorage.getItem('sub'), '', '', '', '');

	}

	ngOnInit() {
	}

	ingresarExpediente(){
		this.sending = true;
		this.secgralService.inExpediente(this.expediente)
			.subscribe(response => {				
				this.inStatus = true;
				this.sending = false;
				this.expediente = new Expediente(null, this.fecha, null, localStorage.getItem('sub'), '', '', '', '');
				
				setTimeout(() => {
					(<any>$('#exampleModal')).modal('hide');
					this.onRefresh(); 
				}, 2000);
			},
			error => {
				console.log(error);
				this.errorStatus = true;
				this.sending = false;
			});
	}

	onRefresh() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

		let currentUrl = this.router.url + '?';

		this.router.navigateByUrl(currentUrl)
		.then(() => {
			this.router.navigated = false;
			this.router.navigate([this.router.url]);
		});
	}

	cargarLista(lista){
		this.listadoNumExp = lista;
		this.listadoNumExp = this.filtrarListado(this.listadoNumExp);
	}

	filtrarListado(array){
		let tempExpArray = [];
		let tempObjArray = [];
		for (var i = 0; i < array.length; i++) {
	        if (tempExpArray.indexOf(array[i].num_expte) == -1) {
	            tempExpArray.push(array[i].num_expte);
	            tempObjArray.push(array[i]);
	        }
	    }
	    return tempObjArray;
	}

	buscarExtracto(numExpte){
		let expSeleccionado;
		expSeleccionado = this.listadoNumExp.find(function(currentValue, index){
			return (<any>currentValue).num_expte == numExpte;
		});

		if (expSeleccionado && expSeleccionado.extracto) {
			this.expediente.extracto = expSeleccionado.extracto;
		}
	}
}

