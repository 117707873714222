import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-modelos',
	templateUrl: './modelos.component.html',
	styleUrls: ['./modelos.component.css']
})
export class ModelosComponent {

	numExpediente: string;
	nivelPermisos: any = localStorage.getItem('access');

	constructor(private route: ActivatedRoute,
				private auth: AuthService){

		this.auth.isActive();
		
		this.numExpediente = "null";
		this.route.paramMap.subscribe(params => {
			if (params.get("expediente")) {
				this.numExpediente = params.get("expediente");
			}
		});
	}
}
