import { Routes} from '@angular/router';
import { MainContentComponent } from './main-content/main-content.component'
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { NormasComponent } from './components/normas/normas.component';
import { ExpedientesComponent } from './components/expedientes/expedientes.component'
import { DetalleNormasComponent } from './components/detalle-normas/detalle-normas.component';
import { EditarNormaComponent } from './components/editar-norma/editar-norma.component';
import { DetalleExpedientesComponent } from './components/detalle-expedientes/detalle-expedientes.component';
import { ModelosComponent } from './components/modelos/modelos.component';
import { NewNormaComponent } from './components/new-norma/new-norma.component';
import { EditarExpedientesComponent } from './components/editar-expedientes/editar-expedientes.component';
import { PasesComponent } from './components/pases/pases.component';
import { PaseExpedienteComponent } from './components/pase-expediente/pase-expediente.component';
import { DetallePasesComponent } from './components/detalle-pases/detalle-pases.component';
import { EditarPasesComponent } from './components/editar-pases/editar-pases.component';
import { NewModeloComponent } from './components/new-modelo/new-modelo.component';
import { EditarModeloComponent } from './components/editar-modelo/editar-modelo.component';
import { ConfigComponent } from './components/config/config.component';
import { NewModeloPaseComponent } from './components/new-modelo-pase/new-modelo-pase.component';
import { NewNormaMultipleComponent } from './components/new-norma-multiple/new-norma-multiple.component';
import { BusquedaAvanzadaComponent } from './components/busqueda-avanzada/busqueda-avanzada.component'
import { ReciboExpedientesComponent } from './components/recibo-expedientes/recibo-expedientes.component';
import { AuthGuard } from './guards/auth.guard';

export const ROUTES: Routes = [
	{ path: 'login', component: LoginComponent, data: { animation: 'login' } },
	{ path: '', component: MainContentComponent, canActivate: [AuthGuard], children: [
		{ path: 'home', component: HomeComponent, data: { animation: 'home' }},
		{ path: '', pathMatch: 'full', redirectTo: 'home' },
		{ path: 'normas-legales', component: NormasComponent, data: { animation: 'normas' } },
		{ path: 'normas-legales/busqueda-avanzada', component: BusquedaAvanzadaComponent, data: { animation: 'buscar-normas' } },
		{ path: 'normas-legales/nueva/:tipo/modelo/:modeloId/expediente/:expediente', component: NewNormaComponent, data: { animation: 'new-normas' } },
		{ path: 'normas-legales/nueva/:tipo/modelo-multiple/:modeloId', component: NewNormaMultipleComponent, data: { animation: 'new-normas' } },
		{ path: 'normas-legales/nueva/copiar/:copiaId', component: NewNormaComponent, data: { animation: 'new-normas' } },
		{ path: 'normas-legales/:id', component: DetalleNormasComponent, data: { animation: 'ver-normas' } },
		{ path: 'normas-legales/editar/:id', component: EditarNormaComponent, data: { animation: 'editar-normas' } },
		{ path: 'normas-legales/ver/:tipo', component: NormasComponent, data: { animation: 'normas' }  },
		{ path: 'expedientes', component: ExpedientesComponent, data: { animation: 'expedientes' } },
		{ path: 'expedientes/ver/:tipo', component: ExpedientesComponent, data: { animation: 'expedientes' }  },
		{ path: 'expedientes/pase/nuevo', component: PaseExpedienteComponent, data: { animation: 'nuevo-pase' } },
		{ path: 'expedientes/pase/nuevo/:id', component: PaseExpedienteComponent, data: { animation: 'nuevo-pase' } },
		{ path: 'expedientes/recibo', component: ReciboExpedientesComponent, data: { animation: 'recibo' } },
		{ path: 'expedientes/:id', component: DetalleExpedientesComponent, data: { animation: 'ver-expedientes' } },
		{ path: 'expedientes/editar/:id', component: EditarExpedientesComponent, data: { animation: 'expedientes' } },
		{ path: 'pases', component: PasesComponent, data: { animation: 'pases' } },
		{ path: 'pases/ver/:id', component: DetallePasesComponent, data: { animation: 'ver-pases' } },
		{ path: 'pases/editar/:id', component: EditarPasesComponent, data: { animation: 'editar-pases' } },
		{ path: 'pases/modelo/nuevo', component: NewModeloPaseComponent, data: { animation: 'pases' } },
		{ path: 'pases/modelo/editar/:id', component: NewModeloPaseComponent, data: { animation: 'pases' } },
		{ path: 'modelos', component: ModelosComponent, data: { animation: 'modelos' } },
		{ path: 'modelos/expediente/:expediente', component: ModelosComponent, data: { animation: 'modelos' } },
		{ path: 'modelos/nuevo', component: NewModeloComponent, data: { animation: 'nuevo-modelo' } },
		{ path: 'modelos/editar/:id', component: EditarModeloComponent, data: { animation: 'editar-modelos' } },
		{ path: 'modelos/copiar/:copia', component: EditarModeloComponent, data: { animation: 'editar-modelos' } },
		{ path: 'configuracion', component: ConfigComponent, data: { animation: 'config' } }
	]},
	{ path: '**', pathMatch: 'full', redirectTo: 'login' }
];