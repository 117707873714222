import { Pipe, PipeTransform } from '@angular/core';
import { Pase } from '../models/pase.model';

@Pipe({
  name: 'pasesFilter'
})
export class PasesFilterPipe implements PipeTransform {

  transform(pase: Pase[], tipoFilter: string): Pase[] {

		if (!pase || !tipoFilter || tipoFilter == 'todo') {
			return pase;
		}

		else{
			return pase.filter( pase => pase.tipo.id == tipoFilter);
		}
		
	}
}
