export class Pase {
	constructor(
		public id:number,
		public numero:number,
		public tipo: any,
		public num_expte: string,
		public fecha: any,
		public referente: string,
		public destinatario: string,
		public area_id: number,
		public user_id: number,
		public expediente_id: number,
		public contenido: string,
		public created_at: any,
		public updated_at: any
	){}
}
